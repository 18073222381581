/* eslint-disable react/jsx-props-no-spreading */
import React, { Suspense } from 'react';
import PropTypes from 'prop-types';
import Button from '../../common/Button';
import SelectedPaymentMethod from '../../common/SelectedPaymentMethod';
import SelectedDeliveryType from '../../common/SelectedDeliveryType';
import styles from './style.module.scss';
import Totals from '../../common/Totals';
import ExpressPaymentData from '../../../containers/express-payment/ExpressPaymentData';
import BraintreeVaultReorder from '../../common/PaymentMethod/BraintreeVaultReorder';
import ReorderTime from '../../common/ReorderTime';
import minicartStyles from "../Minicart/style.module.scss";

const ProductList = React.lazy(() => import('../../common/ProductList'));

const Reorder = (props) => {
  const {
    lastOrderData,
    createLastOrder,
    grandTotal,
    isReorder,
    cartData,
    canCheckout,
    stateShippingMethod,
    setOrderDateTime,
    setOrderTime,
    setLoading,
    billingAddress,
    clearState,
    setCheckoutSuccess,
    savedCards,
    paymentMethod,
    reorderSaveCard,
    cancelReorderAction,
  } = props;

  const isDev = (process.env.NODE_ENV === 'development');
  const coreCartUrl = isDev ? null : window.genecheckout.cartUrl;

  const {
    order_items: orderItems,
    payment = {},
    shipping_address: shippingAddress = {},
    shipping_method: shippingMethod = '',
  } = lastOrderData;

  const orderData = {
    items: orderItems,
  };

  const { cart = { } } = cartData;
  const {
    total_segments: totalSegments,
    email_address: emailAddress,
  } = cart;
  const productData = isReorder ? cartData : orderData;

  const showExpressPayment = () => grandTotal > 0
  && canCheckout && isReorder;

  const startOrderProcess = () => {
    createLastOrder();
  }

  return (
    <div className={styles.reorder}>
      <Suspense fallback={<div>Loading...</div>}>
        <div className={styles.reorder__title}>
          <h3>Order it again</h3>
        </div>

        <div className={styles.reorder__products}>
          <ProductList
            cartData={productData}
            itemUpdate={isReorder}
            removeProduct={isReorder}
          />

          <div className={styles.reorder__actions}>
            <h4>Order Details</h4>

            <ReorderTime
              isReorder={isReorder}
              stateShippingMethod={stateShippingMethod}
              setOrderDateTime={setOrderDateTime}
              setOrderTime={setOrderTime}
            />

            <SelectedDeliveryType address={shippingAddress} shippingMethod={shippingMethod} />

            {(isReorder) && (
              <>
                <SelectedPaymentMethod savedCards={savedCards} shippingMethod={shippingMethod}  />
                <div className={styles.reorder__totals}>
                  <Totals segments={totalSegments} />
                </div>
              </>
            )}

            {(isReorder && !savedCards.length) && (
                <a
                    href={coreCartUrl}
                    className={`action primary ${minicartStyles.proceed}`}
                    disabled={!canCheckout}
                    onClick={() => { cancelReorderAction(); }}
                >
                  Proceed to Basket
                </a>
            )}

            {(isReorder && reorderSaveCard) && (
              <BraintreeVaultReorder
                setLoading={setLoading}
                grandTotal={grandTotal}
                paymentMethod={paymentMethod}
                emailAddress={emailAddress}
                billingAddress={billingAddress}
                clearState={clearState}
                setCheckoutSuccess={setCheckoutSuccess}
                savedCards={savedCards}
              />
            )}

            {(!isReorder) && (
              <Button
                label='Reorder'
                buttonStyle="fullWidth primary"
                trigger={() => {startOrderProcess()}}
              />
            )}

            {(showExpressPayment()) && (
              <ExpressPaymentData />
            )}
          </div>
        </div>
      </Suspense>
    </div>
  );
};

Reorder.propTypes = {
  lastOrderData: PropTypes.shape({}),
  setStoreReorder: PropTypes.func,
  createLastOrder: PropTypes.func,
  isReorder: PropTypes.bool,
  grandTotal: PropTypes.number,
  canCheckout: PropTypes.bool,
  cartData: PropTypes.shape({
    cart_type: PropTypes.string,
    cart: PropTypes.shape({
      total_segments: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          value: PropTypes.number,
          title: PropTypes.string,
        }),
      ),
    }),
  }),
  paymentMethod: PropTypes.shape({
    code: PropTypes.string,
  }),
  stateShippingMethod: PropTypes.shape({
    method_code: PropTypes.string,
    carrier_code: PropTypes.string,
    method_title: PropTypes.string,
    carrier_title: PropTypes.string,
    base_amount: PropTypes.number,
    methodTimes: PropTypes.arrayOf(PropTypes.string),
    methodTime: PropTypes.string,
  }),
  setOrderDateTime: PropTypes.func,
  setOrderTime: PropTypes.func,
  setLoading: PropTypes.func,
  clearState: PropTypes.func,
  billingAddress: PropTypes.shape({}),
  savedCards: PropTypes.arrayOf(
    PropTypes.shape({
      details: PropTypes.string,
      public_hash: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  reorderSaveCard: PropTypes.bool,
  cancelReorderAction: PropTypes.func,
};

Reorder.defaultProps = {
  lastOrderData: {},
  setStoreReorder: () => {},
  createLastOrder: () => {},
  isReorder: false,
  grandTotal: 0,
  canCheckout: false,
  cartData: {
    cart_type: '',
    cart: {
      total_segments: [],
    },
  },
  paymentMethod: {
    code: '',
  },
  stateShippingMethod: {},
  setOrderDateTime: () => {},
  setOrderTime: () => {},
  setLoading: () => {},
  clearState: () => {},
  billingAddress: {},
  savedCards: [],
  reorderSaveCard: false,
  cancelReorderAction: () => {},
};

export default Reorder;
