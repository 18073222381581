import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const ReorderTime = (props) => {
  const {
    isReorder,
    stateShippingMethod,
    setOrderDateTime,
    setOrderTime,
  } = props;


  const [deliveryDateHours, setDeliveryDateHours] = useState('');
  const [deliveryDates, setDeliveryDates] = useState('');
  const [deliveryDate, setDeliveryDate] = useState('');
  const [deliveryTimes, setDeliveryTimes] = useState('');

  useEffect(() => {
    if (isReorder) {
      const {
        method_code: methodCode,
        extension_attributes: {
          delivery_date_hours: methodDeliveryDateHours = [],
        } = {},
      } = stateShippingMethod;

      let methodDeliveryDates;
      if (methodCode === 'STORE') {
        methodDeliveryDates = methodDeliveryDateHours.find((dateHours) => dateHours.delivery_type === 'cc');
      } else if (methodCode.startsWith('apc')) {
        methodDeliveryDates = methodDeliveryDateHours.find((dateHours) => dateHours.delivery_type === 'apc');
      } else {
        methodDeliveryDates = methodDeliveryDateHours.find((dateHours) => dateHours.delivery_type === 'delivery');
      }

      const hours = methodDeliveryDates ? methodDeliveryDates.date_hours : false;
      if (!hours) {
        return false;
      }

      let dateSelect;
      try {
        dateSelect = JSON.parse(hours);
      } catch (e) {
        console.log(e);
        // Return false
        return false;
      }

      setDeliveryDateHours(dateSelect);
      setDeliveryDates(Object.keys(dateSelect));
      setDeliveryDate(Object.keys(dateSelect)[0]);
      setDeliveryTimes(dateSelect[Object.keys(dateSelect)[0]]);
    }
  }, [isReorder]);

  const updateDate = (e) => {
    const date = e.target.value;
    setDeliveryDate(date);
    setDeliveryTimes(deliveryDateHours[date]);
    const time = deliveryDateHours[date][0];

    // Use the first hour
    const hourString = time.split(' - ')[0];

    // get hour/minutes
    const hour = parseInt(hourString, 10);
    const minutes = parseInt(hourString.split(':')[1], 10);

    const deliveryDateTime = new Date(date);
    deliveryDateTime.setHours(hour);
    deliveryDateTime.setMinutes(minutes);

    setOrderDateTime(deliveryDateTime);
    setOrderTime();
  };

  const updateTime = (e) => {
    const time = e.target.value;
    const date = deliveryDate;

    // Use the first hour
    const hourString = time.split(' - ')[0];

    // get hour/minutes
    const hour = parseInt(hourString, 10);
    const minutes = parseInt(hourString.split(':')[1], 10);

    const deliveryDateTime = new Date(date);
    deliveryDateTime.setHours(hour);
    deliveryDateTime.setMinutes(minutes);

    setOrderDateTime(deliveryDateTime);
    setOrderTime();
  };

  const formatDate = (date) => {
    const dateObj = new Date(date);
    return new Intl.DateTimeFormat('en-GB').format(dateObj);
  };

  return (
    <div>
      {(isReorder && deliveryDates && deliveryTimes) && (
      <div className={styles.deliveryDate}>
        <div>
          <label htmlFor="reorderDate">
            <span className={styles.deliveryDate__label}>Date</span>
            <select name="reorderDate" id="reorderDate" onChange={updateDate}>
              {deliveryDates.map((date) => (
                <option value={date}>{formatDate(date)}</option>
              ))}
            </select>
          </label>
        </div>
        {(deliveryTimes.length > 1) && (
        <div>
          <label htmlFor="reorderDate">
            <span className={styles.deliveryDate__label}>Time</span>
            <select onChange={updateTime}>
              {deliveryTimes.map((time) => (
                <option value={time}>{time}</option>
              ))}
            </select>
          </label>
        </div>
        )}
        {(deliveryTimes.length === 1) && (
          <p>
            <span className={styles.deliveryDate__label}>Time</span>
            <span className={styles.deliveryDate__setTime}>{deliveryTimes[0]}</span>
          </p>
        )}
      </div>
      )}
    </div>
  );
};

ReorderTime.propTypes = {
  isReorder: PropTypes.bool,
  stateShippingMethod: PropTypes.shape({
    method_code: PropTypes.string,
    carrier_code: PropTypes.string,
    method_title: PropTypes.string,
    carrier_title: PropTypes.string,
    base_amount: PropTypes.number,
    methodTimes: PropTypes.arrayOf(PropTypes.string),
    methodTime: PropTypes.string,
    extension_attributes: PropTypes.shape({
      delivery_date_hours: PropTypes.arrayOf(PropTypes.shape({})),
    }),
  }),
  setOrderDateTime: PropTypes.func,
  setOrderTime: PropTypes.func,
};

ReorderTime.defaultProps = {
  isReorder: false,
  stateShippingMethod: {},
  setOrderDateTime: () => {},
  setOrderTime: () => {},
};

export default ReorderTime;
