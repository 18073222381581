import axios from 'axios';

import CartMixin from './CartMixin';
import ShippingMixin from './ShippingMixin';
import PaymentMixin from './PaymentMixin';
import CustomerMixin from './CustomerMixin';
import { getCustomerLoggedIn, getCustomerLoggedInFromState } from '../helpers/MageStorage';

const isDev = (process.env.NODE_ENV === 'development');
const magentoApiUrl = isDev ? process.env.RAZZLE_MAGENTO_API_URI : window.genecheckout.apiBaseUrl;
const userAgent = process.env.RAZZLE_USER_AGENT;

const defaultOptions = {
  url: magentoApiUrl,
  userAgent,
  authentication: {
    integration: {
      access_token: undefined,
    },
  },
  headers: {
    'Content-Type': 'application/json',
  },
  isLoggedIn: false,
  maskedId: null,
};

class MagentoApi {
  constructor(options) {
    this.configuration = { ...defaultOptions, ...options };
    this.baseUrl = this.configuration.url;
    this.headers = this.configuration.headers;
    this.isLoggedIn = this.configuration.isLoggedIn;
    this.maskedId = this.configuration.maskedId;

    Object.assign(this, {
      ...CartMixin,
      ...ShippingMixin,
      ...PaymentMixin,
      ...CustomerMixin,
    });

    this.init();
  }

  init() {
    this.isLoggedIn = getCustomerLoggedIn();
  }

  getPath(append = '') {
    return this.baseUrl + append;
  }

  getCartPath(append = '') {
    this.isLoggedIn = getCustomerLoggedIn();
    if (!this.isLoggedIn) {
      this.isLoggedIn = getCustomerLoggedInFromState();
    }

    if (this.isLoggedIn) {
      return this.getPath(`carts/mine/${append}`);
    }

    return this.getPath(`guest-carts/${this.maskedId}/${append}`);
  }

  /**
   * A generic method for handling Magento API requests
   * It can be referenced in the Mixins to create a request
   * @param path
   * @param method
   * @param data
   * @param params
   * @param headers
   * @returns {Promise<{message: *, error: boolean}|string|AxiosResponse<any>>}
   */
  async makeRequest(path, method, data = null, params = null, headers = {}) {
    const options = {
      headers: {
        ...this.headers,
        ...headers,
      },
      method,
      url: path,
      data,
      params,
    };

    try {
      return await axios(options);
    } catch (error) {
      if (error.response) {
        const { data: errorData = {} } = error.response;
        const { message = 'Response failed' } = errorData;

        return {
          message,
          error: true,
        };
      }

      return 'Response failed';
    }
  }

  /**
   * Remove unwanted fields from the address
   * @param address
   * @returns {*}
   */
  formatAddress(address) {
    if (!address) {
      return;
    }

    delete address.id;
    delete address.default_shipping;
    delete address.default_billing;

    // Remove region if it's an object
    const { region } = address;
    if (region && typeof (region) !== 'string') {
      delete address.region;
    }

    return address;
  }

  formatAddressFor3DS(address) {
    if (!address) {
      return;
    }

    let formattedAddress = {
      givenName: address.firstname,
      surname: address.lastname,
      phoneNumber: address.telephone,
      streetAddress: address.street[0],
      locality: address.city,
      postalCode: address.postcode,
    };

    return formattedAddress;
  }
}

// eslint-disable-next-line import/prefer-default-export
export const MagentoData = new MagentoApi();
