/* eslint-disable class-methods-use-this */
import { MagentoData } from './MagentoApi';

const braintreeClient = require('braintree-web/client');
const braintreeData = require('braintree-web/data-collector');
const hostedFields = require('braintree-web/hosted-fields');
const paypalCheckout = require('braintree-web/paypal-checkout');
const threeDSecure = require('braintree-web/three-d-secure');
const googlePayment = require('braintree-web/google-payment');
const applePay = require('braintree-web/apple-pay');

class Braintree {
  constructor() {
    this.hostedFieldsInstance = null;
    this.threeDSecureInstance = null;
    this.applePayInstance = null;
    this.braintreeEnvironment = 'sandbox';
    this.client = null;
    this.deviceData = null;
    this.threeDsChallengeRequested = false;
  }

  async getBraintreeData() {
    const path = MagentoData.getPath('genecheckout/braintree');
    const request = await MagentoData.makeRequest(path, 'get');
    return request;
  }

  getBraintreeEnvironment() {
    return this.braintreeEnvironment;
  }

  /**
   * Braintree client instance needed for all payment methods
   */
  createClient() {
    return this.getBraintreeData().then((response) => {
      const { data = {} } = response;
      const { environment } = data;
      const { three_ds_challenge_requested } = data;
      // Set the mode (Sandbox / Production)
      if (environment) {
        this.braintreeEnvironment = environment;
      } else {
        const e = new Error('Braintree environment not returned');
        throw e;
      }
      this.threeDsChallengeRequested = Boolean(three_ds_challenge_requested);

      return braintreeClient.create({
        authorization: response.data.client_token,
      });
    });
  }

  /**
   * Hosted Fields Init
   * @param object options
   */
  createHostedFieldsInstance(hostedFieldsOptions, setBraintreeReady) {
    // Teardown first if already exists
    if (this.hostedFieldsInstance) {
      this.hostedFieldsInstance.teardown((teardownError) => {
        if (teardownError) {
          throw (teardownError.message);
        }

        this.hostedFieldsInstance = null;
      });
    }


    return this.createClient().then((clientInstance) => {
      this.createThreeDSecureInstance(clientInstance);
      // eslint-disable-next-line no-param-reassign
      hostedFieldsOptions.client = clientInstance;
      return hostedFields.create(hostedFieldsOptions);
    }).then((hostedFields) => {
      this.hostedFieldsInstance = hostedFields;
      setBraintreeReady(true);
    }).catch((error) => {
      const { code } = error;
      setBraintreeReady(true);
      // Let duplicate iframe error fail silently. It does not break functionality.
      if (code !== 'HOSTED_FIELDS_FIELD_DUPLICATE_IFRAME') {
        console.log(error);
      }
    });
  }

  getHostedFieldsInstance() {
    return this.hostedFieldsInstance;
  }

  /**
   * ThreeD secure init from hosted fields
   * @param object clientInstance
   */
  createThreeDSecureInstance(clientInstance) {
    return threeDSecure.create({
      version: 2,
      client: clientInstance,
    }).then((threeDSecureInstance) => {
      this.threeDSecureInstance = threeDSecureInstance;
      // Creation of any other components...
      return braintreeData.create({
        client: clientInstance,
        kount: true,
      }).then((dataCollectorInstance) => {
        this.deviceData = dataCollectorInstance.deviceData;
      });
    }).catch((error) => {
      console.log(error);
    });
  }

  getThreeDSecureInstance() {
    return this.threeDSecureInstance;
  }

  async createPayPalInstance() {
    const clientInstance = await this.createClient();
    return paypalCheckout.create({
      client: clientInstance,
    });
  }

  async payPalPaymentRequest(payload, email, billingAddress, paymentMethod) {
    const paymentRequest = await MagentoData.submitPayment(
      { payment_method_nonce: payload.nonce },
      email,
      billingAddress,
      paymentMethod,
    );

    return paymentRequest;
  }

  async googlePaymentRequest(google) {
    if (!google) {
      return false;
    }

    const clientInstance = await this.createClient();
    const environment = (this.getBraintreeEnvironment() === 'sandbox') ? 'TEST' : 'PRODUCTION';
    const merchantId = (this.getBraintreeEnvironment() === 'sandbox') ? 'merchant-id-from-google' : 'BCR2DN6T7PLJLBRT';
    const googleClientInstance = new google.payments.api.PaymentsClient({
      environment,
    });

    let gPayInstance;
    const buttonPayment = await googlePayment.create({
      client: clientInstance,
      googlePayVersion: 2,
      googleMerchantId: merchantId,
    }).then((googlePaymentInstance) => {
      gPayInstance = googlePaymentInstance;
      return googleClientInstance.isReadyToPay({
        apiVersion: 2,
        apiVersionMinor: 0,
        allowedPaymentMethods: googlePaymentInstance.createPaymentDataRequest().allowedPaymentMethods,
        existingPaymentMethodRequired: true,
      });
    }).then((isReadyToPay) => ({
      isReadyToPay,
      gPayInstance,
      googleClientInstance,
    }));

    return buttonPayment;
  }

  createApplePayInstance() {
    return this.createClient().then((clientInstance) => {
      return applePay.create({
        client: clientInstance,
      });
    }).then((applePayInstance) => {
      // eslint-disable-next-line no-undef
      return ApplePaySession.canMakePaymentsWithActiveCard(applePayInstance.merchantIdentifier)
        .then((canMakePaymentsWithActiveCard) => {
          this.applePayInstance = applePayInstance;
          return this.applePayInstance;
        });
    });
  }
}

// eslint-disable-next-line import/prefer-default-export
export const BraintreeInstance = new Braintree();
