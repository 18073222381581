/* eslint-disable no-use-before-define */
import * as Sentry from '@sentry/browser';
import { MagentoData } from '../../services/MagentoApi';
import { responseHandler } from './common';

import * as checkoutConstant from '../constants/checkout';
import * as customerConstant from '../constants/customer';
import * as shippingConstant from '../constants/shipping';
import * as paymentConstant from '../constants/payment';

// Krispy Kreme Only
import {
  getIsClickAndCollect,
  getStoreData,
  getMageData,
  getDeliveryDateHours,
  getDeliveryDateTime,
} from '../../helpers/MageStorage';

const isClickAndCollect = getIsClickAndCollect();
const { genecheckout: { sentryEnabled = false, storeCode = 'kkuk' } = {} } = window;
// const { genecheckout: { storeCode = 'kkuk' } = {} } = window;

/**
 * Actions
 */

export const setOnCheckoutPage = (checkoutPage) => ({
  type: checkoutConstant.SET_ON_CHECKOUT_PAGE,
  checkoutPage,
});

export const setDeliveryData = (thisDeliveryData) => ({
  type: checkoutConstant.SET_DELIVERY_DATA,
  thisDeliveryData,
});

export const setCartStoreData = (cartData) => ({
  type: checkoutConstant.SET_CART_DATA,
  cartData,
});

export const setStep = (step) => ({
  type: checkoutConstant.SET_CHECKOUT_STEP,
  step,
});

export const setStepValid = (step, isValid) => ({
  type: checkoutConstant.SET_CHECKOUT_STEP_VALID,
  data: { step, isValid },
});

export const setStepEditing = (step, isEditing) => ({
  type: checkoutConstant.SET_CHECKOUT_STEP_EDITING,
  data: { step, isEditing },
});

export const setCheckingOut = (checkingOut) => ({
  type: checkoutConstant.SET_CHECKING_OUT,
  checkingOut,
});

export const clearState = () => ({
  type: checkoutConstant.CLEAR_STATE,
});

export const setLoading = (loading) => ({
  type: checkoutConstant.SET_LOADING,
  loading,
});

export const setAppError = (error) => ({
  type: checkoutConstant.SET_APP_ERROR,
  error,
});

export const setStoreCountries = (countries) => ({
  type: customerConstant.SET_COUNTRIES,
  countries,
});

export const setIsExpressPayment = (expressPayment) => ({
  type: checkoutConstant.SET_EXPRESS_PAYMENT,
  expressPayment,
});

export const setStoreCheckoutSuccess = (checkoutSuccess) => ({
  type: checkoutConstant.SET_CHECKOUT_SUCCESS,
  checkoutSuccess,
});

export const setStoreCustomerEmail = (customerEmail) => ({
  type: customerConstant.SET_CUSTOMER_EMAIL,
  customerEmail,
});

export const setStoreCustomerIsRegistered = (isRegistered) => ({
  type: customerConstant.SET_CUSTOMER_REGISTERED,
  isRegistered,
});

export const setStoreCustomerAddresses = (customerAddresses) => ({
  type: customerConstant.SET_CUSTOMER_ADDRESSES,
  customerAddresses,
});

export const setStoreCustomerShippingAddress = (address) => ({
  type: customerConstant.SET_CUSTOMER_SHIPPING_ADDRESS,
  address,
});

export const setStoreCustomerBillingAddress = (address) => ({
  type: customerConstant.SET_CUSTOMER_BILLING_ADDRESS,
  address,
});

export const setStoreBillingAddressSet = (isSet) => ({
  type: customerConstant.SET_CUSTOMER_BILLING_ADDRESS_SET,
  isSet,
});

export const setStoreShippingAddressSet = (isSet) => ({
  type: customerConstant.SET_CUSTOMER_SHIPPING_ADDRESS_SET,
  isSet,
});

export const setStoreCustomerGiftAddress = (address) => ({
  type: customerConstant.SET_CUSTOMER_GIFT_ADDRESS,
  address,
});

export const setStoreGiftAddressSet = (isSet) => ({
  type: customerConstant.SET_CUSTOMER_GIFT_ADDRESS_SET,
  isSet,
});

export const setStoreLoginStatus = (status) => ({
  type: customerConstant.SET_CUSTOMER_LOGIN_STATUS,
  status,
});

export const setStoreLoginJwt = (jwt) => ({
  type: customerConstant.SET_CUSTOMER_LOGIN_JWT,
  jwt,
});

export const setStoreLoginStep = (step) => ({
  type: customerConstant.SET_CUSTOMER_LOGIN_STEP,
  step,
});

export const setStoreCustomer = (customer) => ({
  type: customerConstant.SET_CUSTOMER,
  customer,
});

export const setStorePaymentCards = (tokens) => ({
  type: checkoutConstant.SET_SAVED_CARDS,
  tokens,
});

export const setCanCheckout = (canCheckout) => ({
  type: checkoutConstant.SET_CAN_CHECKOUT,
  canCheckout,
});

export const setShippingMethods = (methods) => ({
  type: shippingConstant.SET_SHIPPING_METHODS,
  methods,
});

export const setStoreShippingMethod = (method) => ({
  type: shippingConstant.SET_SHIPPING_METHOD,
  method,
});

export const setPaymentMethods = (paymentMethods) => ({
  type: paymentConstant.SET_PAYMENT_METHODS,
  paymentMethods,
});

export const setPaymentMethod = (paymentMethod) => ({
  type: paymentConstant.SET_PAYMENT_METHOD,
  paymentMethod,
});

export const setStoreExpressPaymentData = (expressData) => ({
  type: paymentConstant.SET_EXPRESS_PAYMENT_DATA,
  expressData,
});

export const setStoreReorder = (isReorder) => ({
  type: checkoutConstant.SET_IS_REORDER,
  isReorder,
});

export const setStoreReorderTriggered = (reOrderTriggered) => ({
  type: checkoutConstant.SET_REORDER_TRIGGERED,
  reOrderTriggered,
});

export const setStoreLastOrder = (lastOrderData) => ({
  type: checkoutConstant.SET_LAST_ORDER_DATA,
  lastOrderData,
});

export const setReorderSavedCard = (savedCard) => ({
  type: checkoutConstant.SET_REORDER_SAVED_CARD,
  savedCard,
});

export const cancelStateReorder = (cancelReorder) => ({
  type: checkoutConstant.CANCEL_REORDER,
  cancelReorder,
});

/**
 * Cart
*/

export const setCartData = (pageLoad, resetErrors = true) => {
  return function (dispatch) {
    dispatch(setLoading(true));
    if (resetErrors) {
      dispatch(setAppError(null));
    }
    dispatch(setCanCheckout(true));

    try {
      MagentoData.loadCartData().then(
        (response) => {
          if (response.error) {
            dispatch(setAppError('There was a Response Error.'));
            dispatch(setLoading(false));
            if (sentryEnabled) { Sentry.captureException(response.message); }
            throw (response.message || 'There was an error.');
          }

          const { data } = response;
          // catch any instances where data isn't a cart
          if (typeof data !== 'object' || data === null) {
            dispatch(setAppError('There was an error.'));
            Sentry.captureMessage('Cart Data not an object.');
            if (sentryEnabled) { Sentry.captureException(data); }
            dispatch(setLoading(false));
            throw response;
          }

          // Populate State
          dispatch(setCartStoreData(data));
          dispatch(setLoading(false));

          const { cart_type: cartType, error: cartError, items } = data;

          // Handle cart errors, preventing checkout
          // any product errors?
          const productErrors = Array.isArray(items) ? items.filter((item) => {

            if (Object.prototype.hasOwnProperty.call(item, 'error') && item.error !== '') {
              return item;
            }
          }) : [];

          // display if there are any errors
          if ((typeof cartError === 'string' && cartError.error !== '') || productErrors.length > 0) {
            dispatch(setAppError(cartError || 'There are errors in your cart'));
            dispatch(setCanCheckout(false));

            // bump to minicart
            dispatch(setStep(0));
            dispatch(setCheckingOut(false));

            // If the user has somehow managed to access the checkout
            // bump them to cart
            // eslint-disable-next-line prefer-destructuring
            const pathname = window.location.pathname;
            if (pathname === '/checkout/' || pathname === '/checkout') {
              // eslint-disable-next-line no-undef
              jQuery('body').addClass('checkout-is-hidden');
              dispatch(setLoading(true));
              // eslint-disable-next-line no-undef
              document.location = '/checkout/cart/';
            }
          }

          if (cartType === 'logged_in') {
            const { customer, email_address: emailAddress } = data;
            dispatch(setStoreCustomerEmail(emailAddress));
            dispatch(setCustomer(customer));
            dispatch(setStepValid('email', true));
            dispatch(setStoreLoginStatus({ success: true }));
          } else {
            // remove customer data - they are not logged in.
            dispatch(setStoreLoginStatus({ success: false }));
          }

          // If page load, also refresh shipping methods
          if (pageLoad) {
            dispatch(getShippingMethodsOnLoad());
          }
        },
      );
    } catch (error) {
      dispatch(setAppError(error.message || 'There was an error.'));
      if (sentryEnabled) { Sentry.captureException(error.message); }
      dispatch(setLoading(false));
    }
  };
};

export const updateProductQty = (qty, itemId) => (dispatch) => {
  dispatch(setAppError(null));
  dispatch(setLoading(true));
  return MagentoData.updateProductQty(qty, itemId).then((response) => responseHandler(
    response,
    dispatch,
    setLoading,
    setAppError,
    true,
  )).then(() => {
    dispatch(setCartData(false, false));
  });
};

export const removeProduct = (itemId) => {
  return function (dispatch) {
    dispatch(setAppError(null));
    dispatch(setLoading(true));
    return MagentoData.removeProduct(itemId).then((response) => responseHandler(
      response,
      dispatch,
      setLoading,
      setAppError,
      true,
    )).then(() => {
      dispatch(setCartData());
    });
  };
};

export const applyGiftcard = (code) => {
  return function (dispatch) {
    dispatch(setLoading(true));
    return MagentoData.submitGiftCard(code).then((response) => responseHandler(
      response,
      dispatch,
      setLoading,
      setAppError,
    )).then(() => {
      dispatch(setCartData());
    });
  };
};

export const removeGiftcard = (code) => {
  return function (dispatch) {
    dispatch(setLoading(true));
    return MagentoData.removeGiftCard(code).then((response) => responseHandler(
      response,
      dispatch,
      setLoading,
      setAppError,
    )).then(() => {
      dispatch(setCartData());
    });
  };
};

export const addDonation = () => {
  return function (dispatch) {
    dispatch(setLoading(true));
    return MagentoData.addDonation().then((response) => responseHandler(
      response,
      dispatch,
      setLoading,
      setAppError,
      true
    )).then(() => {
      dispatch(setCartData());
    });
  };
}

export const removeDonation = () => {
  return function (dispatch) {
    dispatch(setLoading(true));
    return MagentoData.removeDonation().then((response) => responseHandler(
      response,
      dispatch,
      setLoading,
      setAppError
    )).then(() => {
      dispatch(setCartData());
    });
  };
}

export const applyCoupon = (code) => {
  return function (dispatch) {
    dispatch(setLoading(true));
    return MagentoData.submitDiscountCode(code).then((response) => responseHandler(
      response,
      dispatch,
      setLoading,
      setAppError,
    )).then(() => {
      dispatch(setCartData());
    });
  };
};

export const removeCoupon = (code) => {
  return function (dispatch) {
    dispatch(setLoading(true));
    return MagentoData.removeDiscountCode(code).then((response) => responseHandler(
      response,
      dispatch,
      setLoading,
      setAppError,
    )).then(() => {
      dispatch(setCartData());
    });
  };
};


/**
 * Customer
*/

export const setCustomerEmail = (customerEmail) => {
  return function (dispatch) {
    return MagentoData.isEmailAddressRegistered(customerEmail).then(
      (response) => {
        console.log('response', response);
        console.log('customerEmail', customerEmail);
        dispatch(setStepValid('email', true));
        dispatch(setStoreCustomerIsRegistered(response));
        dispatch(setStoreCustomerEmail(customerEmail));
      },
    );
  };
};

export const getCountries = () => {
  return function (dispatch) {
    return MagentoData.getCountries().then((result) => {
      const { data = [] } = result;
      return dispatch(setStoreCountries(data));
    });
  };
};

export const getPaymentCards = () => {
  return function (dispatch) {
    return MagentoData.getPaymentTokens().then((result) => {
      const { data = [] } = result;
      return dispatch(setStorePaymentCards(data));
    });
  };
};

export const setCustomer = (customer) => {
  return function (dispatch) {
    const deliveryData = getMageData();
    const { addresses = [] } = customer;

    const defaultShippingAddress = (addresses.filter((address) => address.default_shipping))[0];
    const defaultBillingAddress = (addresses.filter((address) => address.default_billing))[0];

    // Don't set shipping address as customers address if order is c&c
    if (defaultShippingAddress && !isClickAndCollect && deliveryData) {
      const { deliveryPostCode = '' } = deliveryData;

      if (defaultShippingAddress.postcode.trim() === deliveryPostCode.trim()) {
        // Transform region data
        const { region: shippingRegion } = defaultShippingAddress;

        if (shippingRegion) {
          const { region: shippingRegionString = '' } = shippingRegion;
          defaultShippingAddress.region = shippingRegionString;
        } else {
          defaultShippingAddress.region = '';
        }

        dispatch(setStoreCustomerShippingAddress(defaultShippingAddress));
        dispatch(setStoreShippingAddressSet(true));
      }
    }

    if (defaultBillingAddress) {
      // Transform region data
      const { region: billingRegion } = defaultBillingAddress;

      if (billingRegion) {
        const { region: billingRegionString = '' } = billingRegion;
        defaultBillingAddress.region = billingRegionString;
      } else {
        defaultBillingAddress.region = '';
      }

      dispatch(setStoreCustomerBillingAddress(defaultBillingAddress));
      dispatch(setStoreBillingAddressSet(true));
    }

    dispatch(setStoreCustomerAddresses(addresses));
    dispatch(setStoreCustomer(customer));
  };
};

export const customerLoginEmailAddress = (customerEmail, customerPass) => {
  return function (dispatch) {
    dispatch(setLoading(true));
    return MagentoData.submitUserEmailAddress(customerEmail, customerPass).then(
      (response) => {
        const responseData = JSON.parse(response.data);
        dispatch(setStoreLoginJwt(responseData.jwt));
        dispatch(setStoreLoginStep(2));
        dispatch(setCartData());
        dispatch(setStoreCustomerEmail(customerEmail));
        dispatch(setLoading(false));
      },
    );
  };
};

export const customerLogin = (token, customerEmail, customerPass) => {
  return function (dispatch) {
    dispatch(setLoading(true));
    return MagentoData.submitUserPass(token, customerEmail, customerPass).then(
      (response) => {
        const loginFail = {
          success: false,
          message: response.data.message,
        };

        const loginSuccess = {
          success: true,
          message: 'Logging in successfully! Please wait...',
        };

        if (response && response.data && response.data.errors === false) {
          dispatch(setCartData());
          dispatch(setStoreCustomerEmail(customerEmail));
          dispatch(setStoreLoginStatus(loginSuccess));
          dispatch(setCustomer(response));
          dispatch(setLoading(false));
        } else {
          dispatch(setStoreLoginStep(1));
          dispatch(setStoreLoginStatus(loginFail));
          dispatch(setLoading(false));

          setTimeout(() => {

            // Reset the message
            const resetLogin = {
              success: false,
              message: ''
            };

            dispatch(setStoreLoginStatus(resetLogin));
          }, 10000)
        }
      },
    );
  };
};


/**
 * Shipping
 */


// This is not validation - we're just making sure the address is not "set"
// Without these properties. This is due to Loqate.
const requiredAddressParams = [
  'firstname',
  'lastname',
  'postcode',
  'country_id',
  'telephone',
];

export const setShippingAddress = (address, setBillingAddress, clickandcollect) => {
  return function (dispatch) {
    address.same_as_billing = 0;
    if (setBillingAddress && !clickandcollect) {
      dispatch(setStoreCustomerBillingAddress(address));
      dispatch(setStoreBillingAddressSet(true));
      address.same_as_billing = 1;
    }

    let valid = true;
    requiredAddressParams.forEach((param) => {
      if (!address.hasOwnProperty(param)) {
        valid = false;
      }

      if (address.hasOwnProperty(param) && (address[param] === '')) {
        valid = false;
      }
    });

    let setAddress;
    if (clickandcollect) {
      setAddress = {
        firstname: address.firstname,
        lastname: address.lastname,
        telephone: address.telephone,
      };
    } else {
      setAddress = address;
    }

    dispatch(setStoreCustomerShippingAddress(setAddress));
    if (valid) {
      dispatch(setStoreShippingAddressSet(true));
    }
  };
};

export const setBillingAddress = (address) => {
  return function (dispatch) {

    const countryId = Object.prototype.hasOwnProperty.call(address, 'country_id') ? address.country_id : '';
    if (countryId === '') {
      address.country_id = (storeCode == 'kkuk' ? 'GB' : 'IE');
    }
    dispatch(setStoreCustomerBillingAddress(address));

    let valid = true;
    requiredAddressParams.forEach((param) => {
      if (!Object.prototype.hasOwnProperty.call(address, param)) {
        valid = false;
      }

      if (Object.prototype.hasOwnProperty.call(address, param) && (address[param] === '')) {
        valid = false;
      }
    });

    if (!valid) {
        dispatch(setAppError("One or more address fields is missing, please check your address and try again."))
    }

    if (valid) {
      dispatch(setStoreBillingAddressSet(true));
    }
  };
};

export const setGiftAddress = (address) => {
  return function (dispatch) {
    dispatch(setStoreCustomerGiftAddress(address));

    let valid = true;
    requiredAddressParams.forEach((param) => {
      if (!Object.prototype.hasOwnProperty.call(address, param)) {
        valid = false;
      }

      if (Object.prototype.hasOwnProperty.call(address, param) && (address[param] === '')) {
        valid = false;
      }
    });

    if (valid) {
      dispatch(setStoreGiftAddressSet(true));
    }
  };
};


/* eslint-disable */
export const getShippingMethods = (address) => {
  return function (dispatch, getState) {
    dispatch(setLoading(true));
    return MagentoData.getShippingMethods(address).then(
      (response) => {
        dispatch(setLoading(false));
        shippingMethodHandler(response.data, dispatch, getState);
      }
    );
  };
};

export const getShippingMethodsOnLoad = () => {
  return function (dispatch, getState) {
    const {
      customer: {
        form: {
          shippingAddress = {}
        } = {}
      } = {},
      checkout: {
        steps: {
          current = 0
        } = {},
        progress: {
          inCheckout = false
        } = {}
      } = {}
    } = getState();

    if (
      inCheckout &&
      current > 1 &&
      shippingAddress.postcode !== ''
    ) {
      dispatch(setLoading(true));
      return MagentoData.getShippingMethods(shippingAddress).then(
        (response) => {
          dispatch(setLoading(false));
          shippingMethodHandler(response.data, dispatch, getState);
        }
      );
    }
  };
}

const shippingMethodHandler = async (methods, dispatch, getState) => {
  const {
    checkout: {
      shippingMethod = {},
    } = {}
  } = getState();

  if (methods && methods.length > 0) {
    // Does any previously selected method exist in the new data?
    const selectedMethodExists = methods.some((method) => (
      shippingMethod.carrier_code === method.carrier_code
        && shippingMethod.method_code === method.method_code
    ));

    // If it doesn't, reset and force user to choose a new method
    if (!selectedMethodExists) {
      dispatch(setStoreShippingMethod({
        index: 0,
        method_code: '',
        carrier_code: '',
        methodTimes: [],
        methodTime: '',
      }));
      dispatch(setStepValid('shippingMethods', false));
    }
    dispatch(setStoreShippingMethod(methods[0]));
    return dispatch(setShippingMethods(methods));
  }

  return dispatch(resetShippingMethods());
}

const resetShippingMethods = () => {
  return function (dispatch) {
    dispatch(setStoreShippingMethod({
      index: 0,
      method_code: '',
      carrier_code: '',
      methodTimes: [],
      methodTime: '',
    }));
    dispatch(setStepValid('shippingMethods', false));
    dispatch(setShippingMethods([]));
    dispatch(setStep(3));
  };
};

export const setShippingMethod = (method, methodTime) => {
  // Get the corresponding order date/time
  const orderDate = getDeliveryDateTime();
  const hours = getDeliveryDateHours(method);

  const { deliveryDateTime = '' } = orderDate;

  // Use the first hour
  const hourString = methodTime ? methodTime.split(' - ')[0] : hours[0].split(' - ')[0];

  // get hour/minutes
  const hour = parseInt(hourString, 10);
  const minutes = parseInt(hourString.split(':')[1], 10);

  // change the order date time
  deliveryDateTime.setHours(hour);
  deliveryDateTime.setMinutes(minutes);

  const shippingMethod = {
    ...method,
    methodTime: methodTime || (hours ? hours[0] : false),
    methodTimes: hours || [],
  };

  return function (dispatch) {
    dispatch(setOrderDateTime(deliveryDateTime));
    dispatch(setStoreShippingMethod(shippingMethod));
    dispatch(setStepValid('shippingMethods', true));
    dispatch(setStepEditing('shippingMethods', false));
  };
};

export const setOrderDateTime = (orderDateTime) => ({
    type: shippingConstant.SET_ORDER_DATE_TIME,
    orderDateTime,
});

export const setOrderTime = () => {
  return function (dispatch, getState) {
    const { checkout: { orderDateTime = '' } } = getState();

    return MagentoData.submitShippingDateTime(orderDateTime).then(
      (response) => {
        if (response && response.data && response.data.text !== 'Success') {
          dispatch(setAppError(response.message));
          dispatch(setLoading(false));
          dispatch(setStepValid('shippingMethods', false));
          dispatch(setStepEditing('shippingMethods', true));
          dispatch(setStep(3));
          return false;
        }

        return true;
      }
    );
  };
};

export const setShippingInformation = (shippingInformation, currentStep, stepProceed, isClickAndCollect, orderDateTime) => {
  return async function (dispatch, getState) {
    // Pass the gift address
    // GA is only ever shown for c&c orders that have a product/quote item with gift address
    const { customer: { giftAddressSet = false, form: { giftAddress = {} } = {} } = {} } = getState();

    dispatch(setLoading(true));

    // Set the order date/time for standard delivery orders
    if (!isClickAndCollect) {
      await dispatch(setOrderTime(orderDateTime));
    }

    return MagentoData.submitShippingInfo(shippingInformation, giftAddressSet, giftAddress).then(
      (response) => {
        if (response.error) {
          dispatch(setAppError(response.message || 'There was an error.'));
          if (sentryEnabled) { Sentry.captureException(response.message); }
          dispatch(setLoading(false));
          return;
        }

        if (response.status === 200) {
          if (!isClickAndCollect) {
            // Click and collect is always free so let's not waste an API call
            // This also stops the checkout showing during express payment
            dispatch(setCartData());
          }

          if (stepProceed) {
            const { data = {} } = response
            const { payment_methods: paymentMethods } = data;
            dispatch(setStep(currentStep + 1));
            dispatch(setLoading(false));

            const savedCards = paymentMethods.find((method) => method.code === 'braintree_cc_vault');

            if (savedCards) {
              // automatically retrieve saved cards
              dispatch(getPaymentCards());
            }

            return dispatch(setPaymentMethods(paymentMethods));
          }

          dispatch(setLoading(false));
          return true;
        } else {
          dispatch(setAppError(response.message || 'There was an error.'));
          if (sentryEnabled) { Sentry.captureException(response.message); }
          dispatch(setLoading(false));
        }
      }
    );
  };
};


/**
* Payment
*/

/* eslint-disable */
export const setPaymentInformation = (paymentInformation) => {
  return async function (dispatch) {
    dispatch(setLoading(true));

    const {
      additionalData,
      email,
      billingAddress,
      paymentMethod,
    } = paymentInformation;

    return await MagentoData.submitPayment(additionalData, email, billingAddress, paymentMethod).then(
      (response) => {
        if (response.status === 200) {
          dispatch(setCheckoutSuccess());
        } else {
          dispatch(setLoading(false));
          dispatch(setAppError(response.message || 'There was an error.'));
          if (sentryEnabled) { Sentry.captureException(response.message); }
        }
      },
    );
  };
};

export const setExpressPaymentData = (expressData) => {
  // With the normal checkout flow these Redux actions would be triggered as the user
  // progresses

  // With express payments, we need to trigger them all sequentially when the user completes their payment

  return function (dispatch) {
    dispatch(setLoading(true));
    dispatch(setStoreExpressPaymentData(expressData));

    // progress to shipping step
    dispatch(setCheckingOut(true));
    dispatch(setStepValid('email', true));
    dispatch(setStoreCustomerEmail(expressData.email));

    dispatch(setStoreCustomerBillingAddress(expressData.address.billing));
    dispatch(setStoreBillingAddressSet(true));

    const {
      completeOrder = false,
      isClickAndCollect = false,
      shippingMethod = { method_code: '', carrier_code: '' },
    } = expressData;

    // This only happens if Apple Pay or PayPal C&C
    // PayPal non c&c uses the standard checkout interface to select shipping method
    if (completeOrder) {
      let shippingAddress;

      if (isClickAndCollect) {
        const store = getStoreData();

        if (store) {
          shippingAddress = {
            firstname: expressData.address.shipping.firstname,
            lastname: expressData.address.shipping.lastname,
            street: [store.address1, store.address2],
            city: store.city || 'NO STORE CITY',
            region: store.region,
            country_id: store.country,
            postcode: store.postcode,
            company: store.name || 'NO STORE NAME',
            telephone: expressData.address.shipping.telephone,
            same_as_billing: 0,
          };
        } else {
          dispatch(setAppError('Store address missing! Please reload the page and try again.'));
          if (sentryEnabled) { Sentry.captureException('C&C order - store address missing'); }
          return;
        }
      } else {
        shippingAddress = expressData.address.shipping;
      }

      dispatch(setStoreCustomerShippingAddress(shippingAddress));
      dispatch(setStoreShippingAddressSet(true));
      dispatch(setStepValid('shippingAddress', true));

      dispatch(setPaymentMethod({ code: expressData.paymentMethod }));

      const methodCode = isClickAndCollect ? 'STORE' : shippingMethod.method_code;
      const carrierCode = isClickAndCollect ? 'kkcc' : shippingMethod.carrier_code;

      const shippingInformation = {
        methodCode,
        carrierCode,
        shippingAddress: shippingAddress,
        billingAddress: expressData.address.billing,
      }

      const paymentInformation = {
        additionalData: { payment_method_nonce: expressData.paymentData.payment_method_nonce },
        email: expressData.email,
        billingAddress: expressData.address.billing,
        paymentMethod: { code: expressData.paymentMethod },
      }

      return dispatch(setShippingInformation(shippingInformation, 0, false, isClickAndCollect))
      .then(() => dispatch(setPaymentInformation(paymentInformation)));
    }

    // For non c&c PayPal orders, progress to shipping method
    dispatch(setStoreCustomerShippingAddress(expressData.address.shipping));
    dispatch(setStoreShippingAddressSet(true));
    dispatch(setStepValid('shippingAddress', true));
    dispatch(setPaymentMethod({ code: expressData.paymentMethod }));
    dispatch(getShippingMethods(expressData.address.shipping));
    dispatch(setStep(3));
  };
};


export const setCheckoutSuccess = () => {
  return function (dispatch) {
    dispatch(setLoading(true));
    dispatch(setStoreCheckoutSuccess(true));

    dispatch(clearState());

    const isDev = (process.env.NODE_ENV === 'development');
    document.body.classList.add('checkout-is-hidden');
    window.location.href = '/checkout/onepage/success/';
  };
}

/**
 * Reorder
 */

export const cancelReorderAction = () => {
  return function (dispatch, getState) {
    const {
      checkout: {
        isReorder,
      } = {}
    } = getState();
    dispatch(setStoreReorderTriggered(false));
    document.body.classList.remove('checkout-open');

    if (isReorder) {
      dispatch(setStoreReorder(false));
      dispatch(setStep(0));
      dispatch(cancelStateReorder(false));
    }
  };
}

export const loadLastOrder = (pageLoad) => {
  return function (dispatch) {
    dispatch(setLoading(true));
    dispatch(setAppError(null));

    try {
      MagentoData.loadLastCustomerOrder().then(
        (response) => {
          if (response.error) {
            dispatch(setStoreReorderTriggered(false));
            dispatch(setAppError('You have no previous orders.'));
            dispatch(setLoading(false));
            setTimeout(() => {
              document.body.classList.remove('checkout-open');
            }, 2000);
          }

          const { data } = response;
          dispatch(setLoading(false));
          dispatch(setStoreLastOrder(data));
        });
      } catch (error) {
        dispatch(setAppError(error.message || 'There was an error.'));
        if (sentryEnabled) { Sentry.captureException(error.message); }
        dispatch(setLoading(false));
      }
    };
};


export const createLastOrder = () => {
  return function (dispatch, getState) {
    dispatch(setLoading(true));
    dispatch(setAppError(null));

    const {
      checkout: {
        lastOrderData: {
          order_id: orderId = false,
          shipping_address: shippingAddress = false,
          billing_address: billingAddress = false,
          delivery_date: deliveryDate = false,
        } = {},
      } = {}
    } = getState();

    if (
      !orderId ||
      !shippingAddress
    ) {
      dispatch(setLoading(false));
      dispatch(setAppError('Order ID Missing'));
      return false;
    }

    try {
      MagentoData.createOrderFromLast(orderId).then(
        (response) => {
          if (response.error) {
            dispatch(setAppError('There was a Response Error.'));
            dispatch(setLoading(false));
            if (sentryEnabled) { Sentry.captureException(response.message); }
            throw (response.message || 'There was an error.');
          }

          // Delete data not accepted by API
          delete shippingAddress.address_type;
          delete billingAddress.address_type;
          delete shippingAddress.entity_id;
          delete billingAddress.entity_id;
          delete shippingAddress.parent_id;
          delete billingAddress.parent_id;
          delete shippingAddress.region_code;
          delete billingAddress.region_code;

          shippingAddress.same_as_billing = '0';

          dispatch(setReorderSavedCard(true));
          dispatch(setStoreCustomerShippingAddress(shippingAddress));
          dispatch(setStoreShippingAddressSet(true));
          dispatch(setBillingAddress(billingAddress));
          dispatch(setStepValid('shippingAddress', true));
          dispatch(setOrderDateTime(deliveryDate));

          let shippingInformation;
          return dispatch(setOrderTime()).then(() => {
            return dispatch(getShippingMethods(shippingAddress))
              .then(() => {
                const {
                  checkout: {
                    shippingMethods,
                  } = {}
                } = getState();

                if (shippingMethods.length === 0) {
                  dispatch(setLoading(false));
                  dispatch(setAppError('No shipping methods were returned for your reorder. You will need to start a new order.'));
                  return false;
                } else {
                  // always use first method returned
                  const {
                    carrier_code: carrierCode,
                    method_code: methodCode,
                  } = shippingMethods[0];

                  dispatch(setStoreShippingMethod(shippingMethods[0]));

                  shippingInformation = {
                    methodCode,
                    carrierCode,
                    shippingAddress,
                    billingAddress,
                  };

                  return dispatch(setShippingInformation(shippingInformation, 0, true, false, deliveryDate));
                }
              }).then((result) => {
                if (result) {
                  dispatch(setPaymentMethod({ code: 'braintree_cc_vault' }));
                  dispatch(setLoading(false));
                  dispatch(setStoreReorder(true));
                  dispatch(setCartData());
                }
            });
          });
        });
      } catch (error) {
        dispatch(setAppError(error.message || 'There was an error.'));
        if (sentryEnabled) { Sentry.captureException(error.message); }
        dispatch(setLoading(false));
      }
    };
};
