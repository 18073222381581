import { combineReducers } from 'redux';
import checkout from './checkout';
import customer from './customer';

const appReducer = combineReducers({
  checkout,
  customer,
});

export default (state, action) => {
  if (action.type === 'store/CLEAR_STATE') {
    localStorage.setItem('state', {
      checkout: {},
      customer: {},
    });
    state = undefined;
  }

  return appReducer(state, action);
};
