import React from 'react';
import PropTypes from 'prop-types';
import PaymentIcons from '../PaymentIcons';
import styles from './style.module.scss';

const SelectedPaymentMethod = (props) => {
  const { savedCards } = props;

  if (savedCards.length < 1) {
    return <></>;
  }

  const {
    code = '',
    details: {
      type: ccType = '',
      maskedCC: ccLastFour = '',
    } = {},
  } = savedCards[0];

  if (code === 'free') {
    return (<></>);
  }

  const getPaymentIcon = (cardType) => {
    let paymentIcons = [];

    switch (cardType) {
      case 'VI':
        paymentIcons = ['visa'];
        break;
      case 'MC':
        paymentIcons = ['mastercard'];
        break;
      case 'none':
        paymentIcons = [''];
        break;
      default:
        break;
    }

    return paymentIcons;
  };

  const getCCType = (cardTypeCode) => {
    let cardType = '';

    switch (cardTypeCode) {
      case 'VI':
        cardType = 'Visa';
        break;
      case 'MC':
        cardType = 'MasterCard';
        break;
      case 'none':
        cardType = '';
        break;
      default:
        break;
    }

    return cardType;
  };

  return (
    <p className={styles.selectedPaymentMethod}>
      {(code === 'braintree_googlepay') && (
        <svg viewBox="0 0 54 35">
          <use xlinkHref="#icon-googlepay" />
        </svg>
      )}

      {(code === 'braintree_applepay') && (
        <svg viewBox="0 0 54 35">
          <use xlinkHref="#icon-applepay" />
        </svg>
      )}

      {(code === 'braintree_paypal') && (
        <svg viewBox="0 0 49 32">
          <use xlinkHref="#icon-paypal" />
        </svg>
      )}

      {(code === 'braintree_cc_vault') && (
        <PaymentIcons icons={getPaymentIcon(ccType)} />
      )}

      <strong>{'Payment method:\u00A0'}</strong>

      {(code === 'braintree_cc_vault') && (`${getCCType(ccType)} ending in ${ccLastFour}`)}
      {(code === 'braintree_googlepay') && ('Google Pay')}
      {(code === 'braintree_applepay') && ('Apple Pay')}
      {(code === 'braintree_paypal') && ('PayPal')}
    </p>
  );
};

SelectedPaymentMethod.propTypes = {
  savedCards: PropTypes.arrayOf(PropTypes.shape({
    code: PropTypes.string,
    details: PropTypes.shape({
      maskedCC: PropTypes.string,
      type: PropTypes.string,
    }),
  })),
};

SelectedPaymentMethod.defaultProps = {
  savedCards: [
    {
      code: '',
      details: {
        maskedCC: '',
        type: '',
      },
    },
  ],
};

export default SelectedPaymentMethod;
