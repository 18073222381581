import Cookies from 'js-cookie';

const CustomerMixin = {
  this: this,

  async isEmailAddressRegistered(email) {
    const path = this.getPath('customers/isEmailAvailable');
    const data = { customerEmail: email };
    const response = await this.makeRequest(path, 'post', data);

    console.log(response);

    if('data' in response) {
        return !(response.data);
    }
    return false;
  },

  async submitEmailAddress(email) {
    const path = this.getCartPath('billing-address');
    const data = { address: { email } };
    await this.makeRequest(path, 'post', data);
  },

  async submitUserEmailAddress(email, pass) {
    const path = this.getPath('gene-two-part-login/part-one/execute');
    const pathStripped = path.replace('/rest/kkuk/V1', '/rest/V1');
    const formKey = Cookies.get('form_key');
    const data = { username: email, password: pass, form_key: formKey };
    const response = await this.makeRequest(pathStripped, 'post', data, null, { 'X-Requested-With': 'XMLHttpRequest' });
    return response;
  },

  async submitUserPass(token, email, pass) {
    const path = this.getPath('customer/ajax/login');
    const pathStripped = path.replace('/rest/kkuk/V1', '');
    const formKey = Cookies.get('form_key');
    const data = { token: token, username: email, password: pass, form_key: formKey };
    const response = await this.makeRequest(pathStripped, 'post', data, null, { 'X-Requested-With': 'XMLHttpRequest' });
    //const customerPath = this.getPath('genelogin/customer');
    //const customerResponse = await this.makeRequest(customerPath, 'post');
    return response;
  },

  async getCountries() {
    const path = this.getPath('directory/countries');
    const response = await this.makeRequest(path, 'get');
    return response;
  },
};

export default CustomerMixin;
