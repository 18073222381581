/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

import PayPal from '../../common/PaymentMethod/PayPal';
import GooglePay from '../../common/PaymentMethod/GooglePay';
import ApplePay from '../../common/PaymentMethod/ApplePay';

const ExpressPayment = (props) => (
  <>
    <div className={styles.expressPayments}>
      <div className={styles.expressPayment}>
        <PayPal
          {...props}
          expressPayment
        />
        <ApplePay
          {...props}
          expressPayment
        />
        <GooglePay
          {...props}
          expressPayment
        />
      </div>
    </div>
  </>
);

ExpressPayment.propTypes = {
  checkingOut: PropTypes.bool,
  paymentMethod: PropTypes.shape({
    code: PropTypes.string,
  }),
  grandTotal: PropTypes.number,
  billingAddress: PropTypes.shape({}),
  emailAddress: PropTypes.string,
  shippingMethod: PropTypes.shape({
    method_code: PropTypes.string,
    carrier_code: PropTypes.string,
    method_title: PropTypes.string,
    carrier_title: PropTypes.string,
  }),
  setLoading: PropTypes.func,
  clearState: PropTypes.func,
  setExpressPaymentData: PropTypes.func,
  setIsExpressPayment: PropTypes.func,
  setCheckoutSuccess: PropTypes.func,
  setShippingMethods: PropTypes.func,
  setShippingInformation: PropTypes.func,
  isReorder: PropTypes.bool,
};

ExpressPayment.defaultProps = {
  checkingOut: false,
  paymentMethod: {
    code: '',
  },
  grandTotal: 0,
  billingAddress: {},
  emailAddress: '',
  shippingMethod: {},
  setLoading: () => {},
  clearState: () => {},
  setExpressPaymentData: () => {},
  setIsExpressPayment: () => {},
  setCheckoutSuccess: () => {},
  setShippingMethods: () => {},
  setShippingInformation: () => {},
  isReorder: false,
};

export default ExpressPayment;
