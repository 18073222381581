import * as customerConstant from '../constants/customer';

export const initialState = {
  form: {
    emailAddress: '',
    addressFields: [
      {
        name: 'firstname',
        type: 'text',
        required: true,
        displayName: 'First name',
      },
      {
        name: 'lastname',
        type: 'text',
        required: true,
        displayName: 'Last name',
      },
      {
        name: 'street',
        type: 'text',
        count: 2,
        required: true,
        displayName: 'Street',
      },
      {
        name: 'city',
        type: 'text',
        required: true,
        displayName: 'City',
      },
      {
        name: 'country_id',
        type: 'text',
        required: false,
        displayName: 'Country',
      },
      {
        name: 'region',
        type: 'text',
        required: false,
        displayName: 'County',
      },
      {
        name: 'region_code',
        type: 'text',
        required: false,
        displayName: 'Region code',
      },
      {
        name: 'postcode',
        type: 'text',
        required: true,
        displayName: 'Postcode',
      },
      {
        name: 'company',
        type: 'text',
        required: false,
        displayName: 'Company',
      },
      {
        name: 'telephone',
        type: 'tel',
        required: true,
        displayName: 'Telephone',
      },
    ],
    shippingAddress: {
      firstname: '',
      lastname: '',
      street: ['', ''],
      city: '',
      region: '',
      country_id: '',
      postcode: '',
      company: '',
      telephone: '',
    },
    billingAddress: {
      firstname: '',
      lastname: '',
      street: ['', ''],
      city: '',
      region: '',
      country_id: '',
      postcode: '',
      company: '',
      telephone: '',
    },
    giftAddress: {
      firstname: '',
      lastname: '',
      street: ['', ''],
      city: '',
      region: '',
      country_id: '',
      postcode: '',
      company: '',
      telephone: '',
    },
    newsletterSubscribe: false,
    countries: [],
  },
  customerAddresses: [],
  isRegistered: null,
  billingSet: false,
  shippingSet: false,
  giftAddressSet: false,
  loginStatus: {
    success: false,
    message: '',
  },
  loginJwt: '',
  loginStep: 1,
};

const customer = (state = initialState, action) => {
  switch (action.type) {
    case customerConstant.SET_CUSTOMER:
      return { ...state, customer: action.customer };
    case customerConstant.SET_CUSTOMER_EMAIL:
      return { ...state, form: { ...state.form, emailAddress: action.customerEmail } };
    case customerConstant.SET_CUSTOMER_REGISTERED:
      return { ...state, isRegistered: action.isRegistered };
    case customerConstant.SET_CUSTOMER_ADDRESSES:
      return { ...state, customerAddresses: action.customerAddresses };
    case customerConstant.SET_COUNTRIES:
      return { ...state, form: { ...state.form, countries: action.countries } };
    case customerConstant.SET_CUSTOMER_SHIPPING_ADDRESS:
      return { ...state, form: { ...state.form, shippingAddress: { ...state.form.shippingAddress, ...action.address } } };
    case customerConstant.SET_CUSTOMER_BILLING_ADDRESS:
      return { ...state, form: { ...state.form, billingAddress: { ...state.form.billingAddress, ...action.address } } };
    case customerConstant.SET_CUSTOMER_BILLING_ADDRESS_SET:
      return { ...state, billingSet: action.isSet };
    case customerConstant.SET_CUSTOMER_SHIPPING_ADDRESS_SET:
      return { ...state, shippingSet: action.isSet };
    case customerConstant.SET_CUSTOMER_GIFT_ADDRESS:
      return { ...state, form: { ...state.form, giftAddress: { ...state.form.giftAddress, ...action.address } } };
    case customerConstant.SET_CUSTOMER_GIFT_ADDRESS_SET:
      return { ...state, giftAddressSet: action.isSet };
    case customerConstant.SET_CUSTOMER_LOGIN_STATUS:
      return { ...state, loginStatus: action.status };
    case customerConstant.SET_CUSTOMER_LOGIN_JWT:
      return { ...state, loginJwt: action.jwt };
    case customerConstant.SET_CUSTOMER_LOGIN_STEP:
        return { ...state, loginStep: action.step };
    default:
      return state;
  }
};

export default customer;
