import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.scss';

const Loader = ({
  isClickAndCollect,
  loading,
  position,
}) => {
  const wrapperStyle = {
    position,
  };

  if (!loading) {
    return null;
  }

  return (
    <div style={wrapperStyle} className={isClickAndCollect ? `${styles.loaderWrapper} ${styles.loaderWrapperOpaque}` : styles.loaderWrapper}>
      <div className={styles.loader} />
      <div className={styles.loaderMessage}>Just a moment....</div>
    </div>
  );
};

Loader.propTypes = {
  isClickAndCollect: PropTypes.bool,
  loading: PropTypes.bool,
  position: PropTypes.string,
};

Loader.defaultProps = {
  isClickAndCollect: false,
  loading: false,
  position: 'fixed',
};

export default Loader;
