import React from 'react';
import PropTypes from 'prop-types';
import ProductList from '../../common/ProductList';
import Button from '../../common/Button';
import Totals from '../../common/Totals';
import Giftcard from '../../common/Giftcard';
import summaryStyles from './style.module.scss';

import FormatPrice from '../../../helpers/FormatPrice';
import Coupon from '../../common/Coupon';

const OrderSummary = (props) => {
  const {
    currentStep,
    cartData,
    itemUpdate,
    setStep,
    setCheckingOut,
    grandTotal,
    checkingOut,
    checkoutPage,
    checkoutExpanded,
    shrinkCheckout,
    expandCheckout,
    toggleSummary,
    summaryExpanded,
    applyGiftcard,
    removeGiftcard,
    applyCoupon,
    removeCoupon,
    fullPageCheckoutExperience,
  } = props;
  const { cart = { } } = cartData;
  const { total_segments: totalSegments } = cart;

  const isDev = (process.env.NODE_ENV === 'development');

  const showGiftCard = false;
  const showCoupon = true;

  // eslint-disable-next-line no-undef
  const useCoreCart = isDev ? false : window.genecheckout.useCoreCart;
  // eslint-disable-next-line no-undef
  const coreCartUrl = isDev ? null : window.genecheckout.cartUrl;
  const backToCart = () => {
    shrinkCheckout();
    setStep(0);
    setCheckingOut(false);
  };

  if (!checkingOut) {
    return (
      <></>
    );
  }

  const wrapperStyles = () => {
    if (checkoutPage) {
      return `${summaryStyles.summaryWrapper} ${summaryStyles.summaryCheckout}`;
    }

    return summaryStyles.summaryWrapper;
  };

  return (
    <div className={wrapperStyles()}>
      <div>
        <h3 className={summaryStyles.summaryTrigger}>
          <Button
            label={summaryExpanded ? 'Hide Order Summary' : 'Show Order Summary'}
            trigger={toggleSummary}
            buttonStyle="link"
          />
          <span className={summaryStyles.summaryTriggerTitle}>Order Summary</span>
          <span className={summaryStyles.summaryTriggerPrice}>
            {FormatPrice(grandTotal)}
          </span>
        </h3>

        {!summaryExpanded && (
            <div className={summaryStyles.note}>
            <Button label="Have a Discount Code?" trigger={toggleSummary} buttonStyle="link" />
            </div>
        )}

        <div className={summaryExpanded
          ? summaryStyles.summaryContentExpanded : summaryStyles.summaryContent}
        >
          <ul className={summaryStyles.summaryActions}>
            {(!checkoutPage && fullPageCheckoutExperience) && (
              <>
                <li>
                  {checkoutExpanded && (
                    <button type="button" onClick={() => { shrinkCheckout(); }} className="linkAstext">
                      Shrink Checkout
                    </button>
                  )}
                  {!checkoutExpanded && (
                    <button type="button" onClick={() => { expandCheckout(); }} className="linkAstext">
                      Expand Checkout
                    </button>
                  )}
                </li>
              </>
            )}
            {currentStep > 0 && (
              <>
                <li>
                  {(!useCoreCart && !checkoutPage) && (
                    <button type="button" onClick={() => { backToCart(); }} className="linkAstext linkAstext--primary">
                      <strong>Edit your basket</strong>
                    </button>
                  )}
                  {(useCoreCart && checkoutPage) && (
                    <a href={coreCartUrl} className={summaryStyles.linkAstext}><strong>Edit your basket</strong></a>
                  )}
                </li>
              </>
            )}
          </ul>

          {showGiftCard && (
            <div className={summaryStyles.discountForm}>
              <Giftcard
                cartData={cartData}
                applyGiftcard={applyGiftcard}
                removeGiftcard={removeGiftcard}
              />
            </div>
          )}

          {showCoupon && (
            <div className={summaryStyles.discountForm}>
              <Coupon
                cartData={cartData}
                applyCoupon={applyCoupon}
                removeCoupon={removeCoupon}
              />
            </div>
          )}

          <ProductList cartData={cartData} itemUpdate={itemUpdate} />
          <Totals segments={totalSegments} />
        </div>
      </div>
    </div>
  );
};

OrderSummary.propTypes = {
  currentStep: PropTypes.number,
  cartData: PropTypes.shape({
    cart_type: PropTypes.string,
    cart: PropTypes.shape({
      total_segments: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          value: PropTypes.number,
          title: PropTypes.string,
        }),
      ),
    }),
  }),
  setStep: PropTypes.func,
  setCheckingOut: PropTypes.func,
  grandTotal: PropTypes.number,
  checkingOut: PropTypes.bool,
  itemUpdate: PropTypes.func,
  checkoutPage: PropTypes.bool,
  checkoutExpanded: PropTypes.bool,
  applyGiftcard: PropTypes.func,
  removeGiftcard: PropTypes.func,
  applyCoupon: PropTypes.func,
  removeCoupon: PropTypes.func,
  shrinkCheckout: PropTypes.func,
  summaryExpanded: PropTypes.bool,
  toggleSummary: PropTypes.func,
  expandCheckout: PropTypes.func,
  fullPageCheckoutExperience: PropTypes.bool,
};

OrderSummary.defaultProps = {
  currentStep: 0,
  cartData: {
    cart_type: '',
    cart: {
      total_segments: [],
    },
  },
  setStep: () => {},
  setCheckingOut: () => {},
  grandTotal: '',
  checkingOut: false,
  itemUpdate: () => {},
  checkoutPage: false,
  checkoutExpanded: false,
  applyGiftcard: () => {},
  removeGiftcard: () => {},
  applyCoupon: PropTypes.func,
  removeCoupon: PropTypes.func,
  shrinkCheckout: () => {},
  summaryExpanded: false,
  toggleSummary: () => {},
  expandCheckout: () => {},
  fullPageCheckoutExperience: false,
};

export default OrderSummary;
