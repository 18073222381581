const cartMixin = {
  this: this,
  async getCartInfo() {
    const path = this.getCartPath('totals');
    await this.makeRequest(path, 'get');
  },

  async loadCartData() {
    const path = this.getPath('genecheckout/cart');

    const cartData = await this.makeRequest(path, 'get');
    const { data = {} } = cartData;

    this.maskedId = data.masked_id;
    return cartData;
  },

  async loadCartTotals(data) {
    const path = this.getCartPath('totals-information');

    const totalsData = await this.makeRequest(path, 'post', data);
    return totalsData;
  },

  async updateProductQty(qty, itemId) {
    if (!qty || !itemId) {
      return false;
    }

    const data = {
      cartItem: {
        item_id: itemId,
        qty,
        quote_id: this.maskedId,
      },
    };
    const path = this.getCartPath(`items/${itemId}`);
    const response = await this.makeRequest(path, 'put', data);
    return response;
  },

  async removeProduct(itemId) {
    if (!itemId) {
      return false;
    }

    const data = {
      cartItem: {
        item_id: itemId,
        quote_id: this.maskedId,
      },
    };
    const path = this.getCartPath(`items/${itemId}`);
    const response = await this.makeRequest(path, 'delete', data);
    return response;
  },

  async submitDiscountCode(code) {
    const path = this.getCartPath(`coupons/${code}`);
    const response = await this.makeRequest(path, 'put');
    return response;
  },

  async removeDiscountCode() {
    const path = this.getCartPath('coupons');
    const response = this.makeRequest(path, 'delete');
    return response;
  },

  // async addDonation() {
  //   const path = this.getPath('kk-donation/add-donation-product');
  //   const pathStripped = path.replace('/rest/kkuk/V1', '/rest/V1');
  //   const response = await this.makeRequest(pathStripped, 'post');
  //   return response;
  // },
  //
  // async removeDonation() {
  //   const path = this.getPath('kk-donation/remove-donation-product');
  //   const pathStripped = path.replace('/rest/kkuk/V1', '/rest/V1');
  //   const response = this.makeRequest(pathStripped, 'post');
  //   return response;
  // },

  async submitGiftCard(code) {
    const path = this.getCartPath((this.isLoggedIn ? 'giftCards' : `${this.maskedId}/giftCards`));
    const data = {
      giftCardAccountData: {
        gift_cards: [code],
      },
    };

    const response = await this.makeRequest(path, 'post', data);
    return response;
  },

  async removeGiftCard(code) {
    const path = this.getCartPath((this.isLoggedIn ? `giftCards/${code}` : `${this.maskedId}/giftCards/${code}`));
    return await this.makeRequest(path, 'delete');
  },

  async loadLastCustomerOrder() {
    const path = this.getPath('customer/last-order');

    return this.makeRequest(path, 'get');
  },

  async createOrderFromLast(orderId) {
    const path = this.getPath('customer/reorder');

    const data = {
      order_id: orderId,
    };

    return this.makeRequest(path, 'post', data);
  },

};

export default cartMixin;
