const PaymentMixin = {
  this: this,

  async submitPayment(additionalData, email, billingAddress, paymentMethod) {
    const formattedBillingAddress = this.formatAddress(billingAddress);

    const path = this.getCartPath('payment-information');
    const data = {
      cartId: this.maskedId,
      email,
      billingAddress: formattedBillingAddress,
      paymentMethod: {
        method: paymentMethod.code,
        additional_data: additionalData,
      },
    };

    const request = await this.makeRequest(path, 'post', data);
    return request;
  },

  async getPaymentTokens() {
    const path = this.getPath('genecheckout/paymenttoken');
    const response = await this.makeRequest(path, 'get');
    return response;
  },

  async getSavedCardNonce(noncePath, publicHash) {
    const params = {
      public_hash: publicHash,
      cvv: '',
    };
    const response = await this.makeRequest(noncePath, 'get', null, params);
    return response;
  },
};

export default PaymentMixin;
