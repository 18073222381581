import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import throttle from 'lodash/throttle';
import rootReducer from './reducers/index';
import { saveState, loadState } from './localstorage';

const persistedState = loadState();
const store = createStore(rootReducer, persistedState, applyMiddleware(thunk));

store.subscribe(throttle(() => {
  saveState({
    checkout: store.getState().checkout,
    customer: store.getState().customer,
  });
}, 1000));

export default store;
