/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Formik, Form, Field } from 'formik';
import formStyles from '../../../styles/input.module.scss';
import Button from '../Button';

const Giftcard = ({
  applyGiftcard,
  removeGiftcard,
  cartData,
}) => {
  const [showGiftCard, setShowGiftCard] = useState(false);
  const { cart = { } } = cartData;
  const { total_segments: totalSegments = [] } = cart;

  let code;
  totalSegments.forEach((row) => {
    if (row.code === 'giftcardaccount') {
      const data = JSON.parse(row.extension_attributes.gift_cards);
      code = data[0].c;
    }
  });

  return (
    <div>
      {code && (
        <>
          <p>Gift card code: {code}</p>
          <Button buttonStyle="link" label="Remove Gift Card" trigger={() => { removeGiftcard(code); }} />
        </>
      )}
      {!code && (
        <>
          <Button buttonStyle="link" label={showGiftCard ? 'Cancel Gift Card' : 'Apply Gift Card'} trigger={() => { setShowGiftCard(!showGiftCard); }} />
          {showGiftCard && (
            <Formik
              initialValues={{ giftcard: '' }}
              validateOnMount
              validate={(values) => {
                const errors = {};


                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  applyGiftcard(values.giftcard);
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <label className={formStyles.label} htmlFor="giftcard">Enter Giftcard Code</label>
                  <div className={formStyles.fieldGroup}>
                    <div className={formStyles.field} key="giftcard-code">
                      <div className={formStyles.control}>
                        <Field
                          type="text"
                          name="giftcard"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.giftcard}
                        />
                        {errors.giftcard ? (
                          <div className="Alert AlertError">{errors.giftcard}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className={formStyles.formActions}>
                      <button type="submit" disabled={isSubmitting}>
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </>
      )}
    </div>
  );
};

Giftcard.propTypes = {
  applyGiftcard: PropTypes.func,
  removeGiftcard: PropTypes.func,
  cartData: PropTypes.shape({
    cart_type: PropTypes.string,
    cart: PropTypes.shape({
      total_segments: PropTypes.arrayOf(
        PropTypes.shape({
          code: PropTypes.string,
          value: PropTypes.number,
          title: PropTypes.string,
        }),
      ),
    }),
  }),
};

Giftcard.defaultProps = {
  applyGiftcard: () => {},
  removeGiftcard: () => {},
  cartData: {
    cart_type: '',
    cart: {
      total_segments: [],
    },
  },
};

export default Giftcard;
