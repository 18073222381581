/* eslint-disable no-unused-expressions */
/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { BraintreeInstance } from '../../../../services/Braintree';
import { MagentoData } from '../../../../services/MagentoApi';
import styles from './style.module.scss';

// Krispy Kreme Only
import { getIsClickAndCollect } from '../../../../helpers/MageStorage';

const isClickAndCollect = getIsClickAndCollect();


const ApplePay = (props) => {
  const isDev = (process.env.NODE_ENV === 'development');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);
  const [supported, setSupported] = useState(true);

  const [appleLoading, setAppleLoading] = useState(true);
  const [appleSession, setAppleSession] = useState(null);

  const {
    grandTotal,
    emailAddress,
    paymentMethod,
    billingAddress,
    setCheckoutSuccess,
    setLoading,
    expressPayment,
    setExpressPaymentData,
    setIsExpressPayment,
    countries,
  } = props;


  const submitPayment = (nonce) => {
    const email = emailAddress;

    const paymentResponse = MagentoData.submitPayment(
      { payment_method_nonce: nonce },
      email,
      billingAddress,
      paymentMethod,
    );

    paymentResponse.then(() => {
      setSuccess(true);
      setCheckoutSuccess();
    }).catch((err) => {
      console.error(err);
      setLoading(false);
      // @todo error handle order failure
    });
  };

  const getRegionId = (countryCode, regionName) => {
    if (typeof regionName !== 'string') {
      return null;
    }

    // eslint-disable-next-line no-param-reassign
    regionName = regionName.toLowerCase().replace(/[^A-Z0-9]/ig, '');

    if (typeof countries[countryCode] !== 'undefined' && typeof countries[countryCode][regionName] !== 'undefined') {
      return countries[countryCode][regionName];
    }

    return 0;
  };

  let session;

  useEffect(() => {
    if (appleLoading) {
      BraintreeInstance.createApplePayInstance().then((applePayInstance) => {
        setAppleLoading(false);
      }).catch((err) => {
        setSupported(false);
      });
    }

    if (appleSession) {
      setLoading(true);

      let appleShippingAddress = {};
      let appleShippingMethods = [];
      let appleShippingMethod = {};
      let appleGrandTotal = grandTotal;

      appleSession.onvalidatemerchant = (event) => {
        BraintreeInstance.applePayInstance.performValidation({
          validationURL: event.validationURL,
          displayName: 'Krispy Kreme Ltd',
        }, (validationErr, merchantSession) => {
          if (validationErr) {
            appleSession.abort();
            console.error('Braintree ApplePay Error validating merchant:', validationErr);
            setError("We're unable to take payments through Apple Pay at the moment. Please try an alternative payment method.");
            return;
          }

          appleSession.completeMerchantValidation(merchantSession);
        });
      };

      appleSession.onshippingcontactselected = (event) => {
        const address = event.shippingContact;
        // Create a payload.
        const shippingAddress = {
          city: address.locality,
          region: address.administrativeArea,
          country_id: address.countryCode.toUpperCase(),
          postcode: address.postalCode,
          save_in_address_book: 0,
        };

        appleShippingAddress = shippingAddress;

        // estimate methods
        return MagentoData.getShippingMethods(shippingAddress).then((result) => {
          const { data = [] } = result;
          // Stop if no shipping methods.
          if (data.length === 0) {
            appleSession.abort();
            setError('There are no shipping methods available for you right now. Please try again or use an alternative payment method.');
            setLoading(false);
            return false;
          }

          appleShippingMethods = data;
          const newShippingMethods = data.map((thisMethod) => {
            const method = {
              identifier: thisMethod.method_code,
              label: thisMethod.method_title,
              detail: thisMethod.carrier_title ? thisMethod.carrier_title : '',
              amount: parseFloat(thisMethod.amount).toFixed(2),
            };
            return method;
          });

          if (newShippingMethods.length < 1) {
            appleSession.abort();
            setError('There are no shipping methods available for you right now. Please try again or use an alternative payment method.');
            setLoading(false);
            return false;
          }

          const totalsPayload = {
            addressInformation: {
              address: {
                countryId: shippingAddress.country_id,
                region: shippingAddress.region,
                regionId: getRegionId(shippingAddress.country_id, shippingAddress.region),
                postcode: shippingAddress.postcode,
              },
              shipping_method_code: data[0].method_code,
              shipping_carrier_code: data[0].carrier_code,
            },
          };

          const shippingAmount = data[0].amount;
          appleShippingMethod = data[0];

          return MagentoData.loadCartTotals(totalsPayload).then(
            (response) => {
              if (response.error) {
                return;
              }
              const { data } = response;
              appleGrandTotal = data.base_grand_total;

              appleSession.completeShippingContactSelection(
                ApplePaySession.STATUS_SUCCESS,
                newShippingMethods,
                {
                  label: 'Krispy Kreme Ltd',
                  amount: parseFloat(data.base_grand_total).toFixed(2),
                },
                [{
                  type: 'final',
                  label: 'Shipping',
                  amount: parseFloat(shippingAmount).toFixed(2),
                }],
              );
            },
          ).catch((error) => {
            console.log(error);
            setError('We were unable to process your Apple Pay payment. Please try an alternative payment method.');
            setLoading(false);
            appleSession.completePayment(ApplePaySession.STATUS_FAILURE);
          });
        });
      };

      appleSession.onshippingmethodselected = (event) => {
        const selectedShippingMethod = appleShippingMethods.find(
          (method) => method.method_code === event.shippingMethod.identifier,
        );

        appleShippingMethod = selectedShippingMethod;

        const totalsPayload = {
          addressInformation: {
            address: {
              countryId: appleShippingAddress.country_id,
              region: appleShippingAddress.region,
              regionId: getRegionId(appleShippingAddress.country_id, appleShippingAddress.region),
              postcode: appleShippingAddress.postcode,
            },
            shipping_method_code: selectedShippingMethod.method_code,
            shipping_carrier_code: selectedShippingMethod.carrier_code,
          },
        };

        return MagentoData.loadCartTotals(totalsPayload).then(
          (response) => {
            if (response.error) {
              return;
            }
            const { data } = response;
            appleGrandTotal = data.base_grand_total;

            appleSession.completeShippingMethodSelection(
              ApplePaySession.STATUS_SUCCESS,
              {
                label: 'Krispy Kreme Ltd',
                amount: parseFloat(data.base_grand_total).toFixed(2),
              },
              [{
                type: 'final',
                label: 'Shipping',
                amount: parseFloat(selectedShippingMethod.amount).toFixed(2),
              }],
            );
          },
        ).catch((error) => {
          console.log(error);
          setError('We were unable to process your Apple Pay payment. Please try an alternative payment method.');
          setLoading(false);
          appleSession.completePayment(ApplePaySession.STATUS_FAILURE);
        });
      };

      /**
       * Generate payment nonce from Braintree
       */
      appleSession.onpaymentauthorized = (event) => {
        BraintreeInstance.applePayInstance.tokenize({
          token: event.payment.token,
        }, (tokenizeErr, payload) => {
          if (tokenizeErr) {
            console.error('Error tokenizing Apple Pay:', tokenizeErr);
            setError('We were unable to process your Apple Pay payment. Please try an alternative payment method.');
            appleSession.completePayment(ApplePaySession.STATUS_FAILURE);
            setLoading(false);
            return;
          }

          if (expressPayment) {
            let newBillingAddress = event.payment.billingContact;
            let newShippingAddress = event.payment.shippingContact;

            newShippingAddress = {
              street: newShippingAddress.addressLines,
              city: newShippingAddress.locality,
              // eslint-disable-next-line no-nested-ternary
              region: newShippingAddress.administrativeArea,
              country_id: newShippingAddress.countryCode.toUpperCase(),
              postcode: newShippingAddress.postalCode,
              company: '',
              telephone: typeof newShippingAddress.phoneNumber !== 'undefined' ? newShippingAddress.phoneNumber : '00000000000',
              firstname: newShippingAddress.givenName,
              lastname: newShippingAddress.familyName,
              email: newShippingAddress.emailAddress,
            };

            newBillingAddress = {
              street: newBillingAddress.addressLines,
              city: newBillingAddress.locality,
              // eslint-disable-next-line no-nested-ternary
              region: newBillingAddress.administrativeArea,
              country_id: newBillingAddress.countryCode.toUpperCase(),
              postcode: newBillingAddress.postalCode,
              company: '',
              telephone: typeof newBillingAddress.phoneNumber !== 'undefined' ? newBillingAddress.phoneNumber : '00000000000',
              firstname: newBillingAddress.givenName,
              lastname: newBillingAddress.familyName,
              email: newShippingAddress.emailAddress,
            };

            const paymentData = {
              paymentMethod: 'braintree_applepay',
              paymentData: {
                payment_method_nonce: payload.nonce,
              },
              email: newShippingAddress.email,
              address: {
                shipping: newShippingAddress,
                billing: newBillingAddress,
              },
              completeOrder: true,
              isClickAndCollect,
              shippingMethod: appleShippingMethod,
            };

            setIsExpressPayment(true);
            setExpressPaymentData(paymentData);
          } else {
            submitPayment(payload.nonce);
          }
        });
      };

      appleSession.begin();
    }
  }, [
    appleLoading,
    expressPayment,
    appleSession,
  ]);

  const payNow = async () => {
    setError(false);

    let requiredShippingFields = [];
    let requiredBillingFields = [];

    if (expressPayment) {
      requiredShippingFields = ['postalAddress', 'name', 'email', 'phone'];
      requiredBillingFields = ['postalAddress', 'name'];
    }

    try {
      const paymentRequest = BraintreeInstance.applePayInstance.createPaymentRequest({
        total: {
          label: 'Krispy Kreme Ltd',
          amount: parseFloat(grandTotal).toFixed(2),
        },
        requiredShippingContactFields: requiredShippingFields,
        requiredBillingContactFields: requiredBillingFields,
      });
      session = new ApplePaySession(3, paymentRequest);
      setAppleSession(session);
    } catch (err) {
      console.log(err);
      setError("We're unable to take payments through Apple Pay at the moment. Please try an alternative payment method.");
    }
  };

  return (
    <div>
      {supported && appleLoading && (
        <p>Loading...</p>
      )}
      {(supported && !appleLoading) && (
        <>
          <div
            id="apple-pay-btn"
            role="button"
            className={styles.ApplePayButton}
            onClick={() => { payNow(); }}
          />
          {(error) && (<div className="Alert AlertError">{error}</div>)}
          {(success) && (<p>Success! Please wait...</p>)}
          {(success && isDev) && (<p>Dev mode:Please manually clear your quote from browser data.</p>)}
        </>
      )}
    </div>
  );
};

ApplePay.propTypes = {
  paymentMethod: PropTypes.shape({
    code: PropTypes.string,
  }),
  grandTotal: PropTypes.number,
  billingAddress: PropTypes.shape({}),
  emailAddress: PropTypes.string,
  setLoading: PropTypes.func,
  setCheckoutSuccess: PropTypes.func,
  expressPayment: PropTypes.bool,
  setExpressPaymentData: PropTypes.func,
  setIsExpressPayment: PropTypes.func,
  countries: PropTypes.arrayOf(PropTypes.shape({
    full_name_english: PropTypes.string,
    two_letter_abbreviation: PropTypes.string,
  })),
};

ApplePay.defaultProps = {
  paymentMethod: {
    code: '',
  },
  grandTotal: 0,
  billingAddress: {},
  emailAddress: '',
  setLoading: () => {},
  setCheckoutSuccess: () => {},
  expressPayment: false,
  setExpressPaymentData: () => {},
  setIsExpressPayment: () => {},
  countries: [],
};

export default ApplePay;
