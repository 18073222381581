import { connect } from 'react-redux';
import OrderSummary from '../../components/wrappers/OrderSummary';

import {
  setStep,
  setCheckingOut,
  applyGiftcard,
  removeGiftcard,
  applyCoupon,
  removeCoupon,
} from '../../store/actions/checkout';

const mapStateToProps = (state) => ({
  cartData: state.checkout.cart,
  grandTotal: state.checkout.cart.grandTotal,
  checkingOut: state.checkout.progress.inCheckout,
  currentStep: state.checkout.steps.current,
});

const mapDispatchToProps = (dispatch) => ({
  setStep: (step) => dispatch(setStep(step)),
  setCheckingOut: (checkingOut) => dispatch(setCheckingOut(checkingOut)),
  applyGiftcard: (code) => dispatch(applyGiftcard(code)),
  removeGiftcard: (code) => dispatch(removeGiftcard(code)),
  applyCoupon: (code) => dispatch(applyCoupon(code)),
  removeCoupon: (code) => dispatch(removeCoupon(code)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(OrderSummary);
