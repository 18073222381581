/* eslint-disable no-undef */

export const getCustomerLoggedIn = () => {
  const mageStorage = localStorage.getItem('mage-cache-storage');
  let mageData = {};

  if (mageStorage) {
    try {
      mageData = JSON.parse(mageStorage);
    } catch (error) {
      console.log(error);
    }
  }

  return (mageData.customer && typeof mageData.customer.fullname === 'string' && typeof mageData.customer.firstname === 'string');
};

export const getCustomerLoggedInFromState = () => {
  const stateStorage = localStorage.getItem('state');
  let state = {};

  if (stateStorage) {
    try {
      state = JSON.parse(stateStorage);
    } catch (error) {
      console.log(error);
    }
  }

  return (state.customer && state.customer.loginStatus && state.customer.loginStatus.success);
};

// is missing from local storage
export const getMageData = () => {
  const mageStorage = localStorage.getItem('mage-cache-storage');
  let mageData = {};

  if (mageStorage) {
    try {
      mageData = JSON.parse(mageStorage);
    } catch (error) {
      console.log(error);
    }
  }

  // Check we have the data we need from local storage
  const deliveryData = ((mageData['delivery-data'] && mageData['delivery-data'].deliveryType)
    && (mageData['delivery-data'] && mageData['delivery-data'].deliveryDateTime)
    && (mageData['delivery-data'] && mageData['delivery-data'].selectedDeliveryStore))
    ? mageData['delivery-data'] : window.deliveryData;

  return deliveryData;
};

export const getDeliveryPostcode = () => {
  const deliveryData = getMageData();

  if (!deliveryData) {
    return false;
  }

  if (deliveryData && deliveryData.hasOwnProperty('deliveryPostCode')) {
    return deliveryData.deliveryPostCode;
  }

  return false;
};

export const getStoreData = () => {
  const deliveryData = getMageData();

  if (!deliveryData) {
    return false;
  }

  const { selectedDeliveryStore = {} } = deliveryData;

  return selectedDeliveryStore;
};

export const getIsClickAndCollect = () => {
  const deliveryData = getMageData();

  if (!deliveryData) {
    return false;
  }

  const { deliveryType = '' } = deliveryData;
  return (deliveryType === 'kkcc' || deliveryType === 'fundraise');
};

export const getDeliveryDateTime = () => {
  const deliveryData = getMageData();

  if (!deliveryData) {
    return false;
  }

  let { deliveryDateTime = '' } = deliveryData;

  deliveryDateTime = deliveryDateTime.replace(/ /g, 'T');
  deliveryDateTime = new Date(deliveryDateTime);

  return {
    time: deliveryDateTime.toTimeString(),
    date: deliveryDateTime.toDateString(),
    deliveryDateTime,
  };
};

export const getClickAndCollectDateTime = () => {
  const deliveryData = getMageData();

  if (!deliveryData) {
    return false;
  }

  const {
    selectedDeliveryDate = '',
    selectedDeliveryTime = '',
  } = deliveryData;

  return {
    selectedDeliveryTime,
    selectedDeliveryDate,
  };
};

export const getDeliveryDateHours = (method) => {
  const { method_code: methodCode = '' } = method;
  const deliveryData = getMageData();

  if (!deliveryData) {
    return false;
  }

  const storeData = getStoreData();

  const { deliveryDateTime = '' } = deliveryData;
  const deliveryDate = deliveryDateTime.split(' ')[0];
  const { delivery_date_hours: deliveryDateHours = [] } = storeData;

  let hours;
  if (methodCode.startsWith('apc')) {
    hours = deliveryDateHours.find((dateHours) => dateHours.delivery_type === 'apc').date_hours;
  } else {
    hours = deliveryDateHours.find((dateHours) => dateHours.delivery_type === 'delivery').date_hours;
  }


  let hoursJson;
  try {
    hoursJson = JSON.parse(hours);
  } catch (e) {
    console.log(e);
    // Return false
    return false;
  }

  // Data returned from window object is an array
  if (Array.isArray(hoursJson)) {
    // eslint-disable-next-line prefer-destructuring
    hoursJson = hoursJson[0];
  }

  const dateHours = hoursJson[deliveryDate];

  if (!dateHours) {
    return false;
  }

  return dateHours;
};


export default getMageData;
