import React from 'react';
import Modal from 'react-modal';
import PropTypes from 'prop-types';
import Button from '../Button';
import styles from './style.module.scss';

Modal.setAppElement('#root');

const customStyles = (large) => {
  let height = '300px';
  if (large) {
    height = '580px';
  }

  return {
    overlay: {
      zIndex: '9999',
      backgroundColor: 'rgba(0, 0, 0, 0.28)',
    },
    content: {
      width: '90%',
      top: '50%',
      left: '50%',
      transform: 'translateY(-50%) translateX(-50%)',
      textAlign: 'center',
      height,
      maxWidth: '720px',
      borderRadius: '20px',
      boxShadow: '0px 0px 34px 0px rgba(0, 0, 0, 0.5019607843137255)',
      maxHeight: '80vh',
      overflow: 'auto',
    },
  };
};

const CheckoutModal = ({
  isOpen,
  onRequestClose,
  contentLabel,
  modalTitle,
  modalContent,
  exitModal,
  confirmAction,
  showClose,
  children,
  showActions,
  large,
  confirmLabel,
}) => (
  <Modal
    style={customStyles(large)}
    isOpen={isOpen}
    onRequestClose={onRequestClose}
    contentLabel={contentLabel}
  >
    <div>
      {showClose && (
        <div className={styles.modalClose}>
          <Button trigger={exitModal} iconType="close" label="close" showLabel={false} buttonStyle="close link" />
        </div>
      )}
      <h2 className={styles.modalTitle}>{modalTitle}</h2>
      {modalContent && (<p>{modalContent}</p>)}
      {children}
      {showActions && (
        <div className={styles.modalActions}>
          <Button label={confirmLabel} trigger={() => { confirmAction(); }} buttonStyle="fullWidth primary" type="submit" />
          <Button label="Cancel" trigger={() => { exitModal(); }} buttonStyle="link" type="submit" />
        </div>
      )}
    </div>
  </Modal>
);

CheckoutModal.propTypes = {
  isOpen: PropTypes.bool,
  onRequestClose: PropTypes.func,
  contentLabel: PropTypes.string,
  modalTitle: PropTypes.string,
  modalContent: PropTypes.string,
  exitModal: PropTypes.func,
  confirmAction: PropTypes.func,
  showClose: PropTypes.bool,
  children: PropTypes.func,
  showActions: PropTypes.bool,
  large: PropTypes.bool,
  confirmLabel: PropTypes.string,
};

CheckoutModal.defaultProps = {
  isOpen: false,
  onRequestClose: () => {},
  contentLabel: '',
  modalTitle: '',
  modalContent: '',
  exitModal: () => {},
  confirmAction: () => {},
  showClose: true,
  children: () => {},
  showActions: false,
  large: false,
  confirmLabel: 'Confirm',
};

export default CheckoutModal;
