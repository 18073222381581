import * as Sentry from '@sentry/browser';

const { genecheckout: { sentryEnabled = false } = {} } = window;

// eslint-disable-next-line import/prefer-default-export
export const responseHandler = (
  response,
  dispatch,
  setLoading,
  setAppError,
  updateLocalstorage = false,
) => {
  if (response.status === 200) {
    if (updateLocalstorage && window.jQuery) {
      jQuery(document).ready(() => {
        jQuery(document).trigger('ajax:headlessCartUpdate');
      });
    }

    return true;
  }

  if (response.error) {
    dispatch(setLoading(false));
    dispatch(setAppError(response.message || 'There was an error.'));
    if (sentryEnabled) { Sentry.captureException(response.message); }

    return false;
  }

  return false;
};
