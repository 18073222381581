import { connect } from 'react-redux';
import Reorder from '../../components/wrappers/Reorder';

import {
  setStoreReorderTriggered,
  createLastOrder,
  setOrderDateTime,
  setOrderTime,
  clearState,
  setLoading,
  setCheckoutSuccess,
  cancelReorderAction,
} from '../../store/actions/checkout';

const mapStateToProps = (state) => ({
  lastOrderData: state.checkout.lastOrderData,
  reOrderTriggered: state.checkout.reOrderTriggered,
  isReorder: state.checkout.isReorder,
  paymentMethod: state.checkout.paymentMethod,
  grandTotal: state.checkout.cart.grandTotal,
  canCheckout: state.checkout.canCheckout,
  stateShippingMethod: state.checkout.shippingMethod,
  cartData: state.checkout.cart,
  billingAddress: state.customer.form.billingAddress,
  savedCards: state.checkout.savedCards,
  reorderSaveCard: state.checkout.reorderSaveCard,
});

const mapDispatchToProps = (dispatch) => ({
  setStoreReorderTriggered:
  (reOrderTriggered) => dispatch(setStoreReorderTriggered(reOrderTriggered)),
  createLastOrder: () => dispatch(createLastOrder()),
  setOrderDateTime: (dateTime) => dispatch(setOrderDateTime(dateTime)),
  setOrderTime: () => dispatch(setOrderTime()),
  clearState: () => dispatch(clearState()),
  setLoading: (loading) => dispatch(setLoading(loading)),
  setCheckoutSuccess: (checkoutSuccess) => dispatch(setCheckoutSuccess(checkoutSuccess)),
  cancelReorderAction: () => dispatch(cancelReorderAction()),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Reorder);
