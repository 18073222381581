// eslint-disable-next-line no-undef
// eslint-disable-next-line camelcase
__webpack_public_path__ = window.genecheckout ? `${window.genecheckout.staticPath}/` : '';
/* eslint-disable import/first */
import './styles/_variables.scss';
import './App.scss';
import React from 'react';
import { Provider } from 'react-redux';
import * as Sentry from '@sentry/browser';

const { genecheckout: { sentryEnabled = false } = {} } = window;
if (sentryEnabled) {
  Sentry.init({ dsn: 'https://04ab831c89cd4ef18f5b474af080e531@o327851.ingest.sentry.io/5276519' });
}

import store from './store/create-store';
import { setCartData, getCountries } from './store/actions/checkout';
import CheckoutData from './containers/checkout/CheckoutData';
import KeyboardHandler from './helpers/KeyboardHandler';
import MagentoPageEvents from './helpers/MagentoPageEvents';

KeyboardHandler();
MagentoPageEvents(store);

/* eslint-disable */
const Promise = require('es6-promise').Promise;
require('es6-promise/auto');
/* eslint-enable */

const App = () => {
  store.dispatch(setCartData(true));
  store.dispatch(getCountries());

  return (
    <>
      <Provider store={store}>
        <CheckoutData />
      </Provider>
    </>
  );
};

export default App;