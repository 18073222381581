import React from 'react';
import PropTypes from 'prop-types';

import styles from './style.module.scss';

const Button = ({
  label,
  iconType,
  trigger,
  showLabel,
  buttonStyle,
  type,
  disabled,
  classes,
}) => {
  const Icons = {
    close: (
      <svg viewBox="0 0 20 20">
        <path d="M10 8.334l-8.334-8.334-1.666 1.666 8.334 8.334-8.334 8.334 1.666 1.666 8.334-8.334 8.334 8.334 1.666-1.666-8.334-8.334 8.334-8.334-1.666-1.666-8.334 8.334z" />
      </svg>
    ),
    delete: (
      <svg viewBox="0 0 25 25">
        <path fill="#9A9B9D" d="M8.972 10.742h.011c.206 0 .373.158.373.355l.524 9.384a.364.364 0 0 1-.373.355h-.01a.364.364 0 0 1-.374-.355L8.6 11.097c0-.197.167-.355.373-.355zm3.146.355c0-.197.167-.355.373-.355h.011c.206 0 .373.158.373.355v9.384a.364.364 0 0 1-.373.355h-.01a.364.364 0 0 1-.374-.355v-9.384zm3.535 0c0-.197.167-.355.373-.355h.011c.206 0 .373.158.373.355l-.54 9.384a.364.364 0 0 1-.373.355h-.011a.364.364 0 0 1-.373-.355l.54-9.384zm-8.845 10.46c0 .796.678 1.443 1.514 1.443h8.35c.835 0 1.513-.647 1.513-1.443l.746-12.979H6.062l.746 12.98zM10.296 5.148a2.25 2.25 0 0 1 .626-1.373c.418-.417.97-.649 1.56-.656a2.21 2.21 0 0 1 1.566.642c.38.373.604.857.641 1.387h-4.393zm7.534 0h-2.014a3.3 3.3 0 0 0-.985-2.186A3.26 3.26 0 0 0 12.474 2a3.292 3.292 0 0 0-2.342.992 3.274 3.274 0 0 0-.955 2.156H7.17c-1.238 0-2.237 1.186-2.237 2.64v.046h15.134v-.046c0-1.454-1-2.64-2.237-2.64z" />
      </svg>
    ),
    Postcode: (
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
        <path fillRule="evenodd" d="M10.386 5.16l6.475 6.806a.5.5 0 0 1 .01.68l-6.43 7.183a.519.519 0 0 1-.726.044l-1.539-1.338a.5.5 0 0 1-.045-.715l4.762-5.319a.2.2 0 0 0-.004-.272L8.14 7.236a.501.501 0 0 1 .023-.715L9.66 5.137a.52.52 0 0 1 .726.023" />
      </svg>
    ),
    search: (
      <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25">
        <g fill="none" fillRule="evenodd" transform="rotate(-30 15.281 -.513)">
          <path fill="#F2B224" d="M5.786 19.692l-.322-6.396c0-.483.464-.87.98-.87l1.644-.084c.516 0 .963.386.963.87l.322 6.396c0 .483-.447.87-.963.87l-1.644.084c-.516 0-.98-.345-.98-.87z" />
          <circle cx="6.817" cy="6.585" r="6" stroke="#F2B224" strokeWidth="2" />
        </g>
      </svg>
    ),
    expand: (
      <svg xmlns="http://www.w3.org/2000/svg" height="25" viewBox="0 96 960 960" width="48">
        <path d="M480 711 240 471l43-43 197 198 197-197 43 43-240 239Z" />
      </svg>
    ),
  };

  const getIcon = () => Icons[iconType];

  // This is pretty horrible and needs to be refactored
  // @todo refactor
  const getClasses = () => {

    if (buttonStyle.includes('primary')) {
      classes = `${classes} ${styles.buttonPrimary}`;
    }

    if (buttonStyle.includes('secondary')) {
      classes = `${classes} ${styles.buttonSecondary}`;
    }

    if (buttonStyle.includes('ghost')) {
      classes = `${classes} ${styles.buttonGhost}`;
    }

    if (buttonStyle.includes('close')) {
      classes = `${classes} ${styles.buttonClose}`;
    }

    if (buttonStyle.includes('delete')) {
      classes = `${classes} ${styles.buttonDelete}`;
    }

    if (buttonStyle.includes('fullWidth')) {
      classes = `${classes} ${styles.buttonFullwidth}`;
    }

    if (buttonStyle.includes('link')) {
      classes = `${classes} ${styles.buttonAsLink}`;
    }

    if (buttonStyle.includes('proceed')) {
      classes = `${classes} ${styles.buttonProceed}`;
    }

    if (buttonStyle.includes('Postcode')) {
      classes = `${classes} ${styles.buttonIcon} ${styles.buttonPostcode}`;
    }

    if (buttonStyle.includes('loqate')) {
      classes = `${classes} ${styles.buttonLoqate}`;
    }

      if (buttonStyle.includes('addressSearch')) {
          classes = `${classes} ${styles.buttonAddressSearchExpand}`;
      }

    return classes;
  };

  return (
    <>
      <button
        // eslint-disable-next-line react/button-has-type
        type={type}
        onClick={() => trigger()}
        className={getClasses()}
        disabled={disabled}
      >
        {showLabel && (<span>{label}</span>)}
        {getIcon() && (<span className={styles.buttonIcon}>{getIcon()}</span>)}
      </button>
    </>
  );
};

Button.propTypes = {
  label: PropTypes.string,
  showLabel: PropTypes.bool,
  iconType: PropTypes.string,
  trigger: PropTypes.func,
  buttonStyle: PropTypes.string,
  type: PropTypes.string,
  disabled: PropTypes.bool,
  classes: PropTypes.string,
};

Button.defaultProps = {
  label: '',
  showLabel: true,
  iconType: '',
  trigger: () => {},
  buttonStyle: 'primary',
  type: 'button',
  disabled: false,
  classes: '',
};

export default Button;
