import { connect } from 'react-redux';
import {
  setStep,
  setStepEditing,
  setStepValid,
  setCheckingOut,
  setIsExpressPayment,
} from '../../store/actions/checkout';

import Steps from '../../components/wrappers/Steps';

const mapStateToProps = (state) => ({
  steps: state.checkout.steps,
  currentStep: state.checkout.steps.current,
  checkingOut: state.checkout.progress.inCheckout,
  isExpress: state.checkout.isExpress,
  cartItems: state.checkout.cart.items,
});

const mapDispatchToProps = (dispatch) => ({
  setStep: (step) => dispatch(setStep(step)),
  setStepValid: (step, isValid) => dispatch(setStepValid(step, isValid)),
  setStepEditing: (step, isEditing) => dispatch(setStepEditing(step, isEditing)),
  setCheckingOut: (checkingOut) => dispatch(setCheckingOut(checkingOut)),
  setIsExpressPayment: (isExpress) => dispatch(setIsExpressPayment(isExpress)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Steps);
