const ShippingMixin = {
  this: this,

  async getShippingMethods(shippingAddress = {}) {
    const formattedShippingAddress = this.formatAddress(shippingAddress);
    const path = this.getCartPath('estimate-shipping-methods');
    const data = {
      address: formattedShippingAddress,
    };

    const response = await this.makeRequest(path, 'post', data);
    return response;
  },

  async submitShippingInfo(shippingInformation, isGiftOrder, giftAddress) {
    const {
      shippingAddress,
      billingAddress,
      methodCode,
      carrierCode,
    } = shippingInformation;
    const formattedShippingAddress = this.formatAddress(shippingAddress);
    const formattedBillingAddress = this.formatAddress(billingAddress);

    const path = this.getCartPath('shipping-information');
    let data = {
      addressInformation: {
        shipping_address: formattedShippingAddress,
        billing_address: formattedBillingAddress,
        shipping_method_code: methodCode,
        shipping_carrier_code: carrierCode,
      },
    };

    if (isGiftOrder) {
      data = {
        ...data,
        addressInformation: {
          ...data.addressInformation,
          extension_attributes: {
            gift_address: giftAddress,
          },
        },
      };
    }

    const response = await this.makeRequest(path, 'post', data);
    return response;
  },

  async submitShippingDateTime(orderDateTime) {
    const path = this.getPath('kk/deliverydate');
    const response = await this.makeRequest(path, 'post', { deliveryDate: orderDateTime });
    return response;
  },
};

export default ShippingMixin;
