/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { BraintreeInstance } from '../../../../services/Braintree';
import { MagentoData } from '../../../../services/MagentoApi';
import Button from '../../Button';
import styles from './style.module.scss';

const BraintreeVaultReorder = (props) => {
  const isDev = (process.env.NODE_ENV === 'development');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(false);

  const {
    emailAddress,
    paymentMethod,
    billingAddress,
    setLoading,
    setCheckoutSuccess,
    savedCards,
    grandTotal,
  } = props;

  const payNow = async () => {
    if (savedCards.length < 1) {
      return false;
    }

    setError(false);
    setLoading(true);

    const savedCard = savedCards[0];

    const {
      nonceUrl,
      publicHash,
    } = savedCard;

    const email = emailAddress;
    // Get the Nonce
    const paymentNonceRequest = await MagentoData.getSavedCardNonce(nonceUrl, publicHash);

    if (paymentNonceRequest.status === 200) {
      const { data: { paymentMethodNonce = '' } = {} } = paymentNonceRequest;

      const threeDInnerContainer = document.createElement('div');
      threeDInnerContainer.className = 'braintree-3dsecure-popup';

      await BraintreeInstance.createClient().then(
        (clientInstance) => BraintreeInstance.createThreeDSecureInstance(clientInstance),
      );

      const threeDSecureResponse = await BraintreeInstance.threeDSecureInstance.verifyCard({
        amount: parseFloat(grandTotal).toFixed(2),
        nonce: paymentMethodNonce,
        addFrame(err, iframe) {
          // Set up your UI and add the iframe.
          threeDInnerContainer.appendChild(iframe);
          document.body.appendChild(threeDInnerContainer);
          document.body.classList.add('_with-3d-secure');
        },
        removeFrame() {
          document.body.removeChild(threeDInnerContainer);
          document.body.classList.remove('_with-3d-secure');
        },
        onLookupComplete(data, next) {
          next();
        },
      });

      const liability = {
        shifted: threeDSecureResponse.liabilityShifted,
        shiftPossible: threeDSecureResponse.liabilityShiftPossible,
      };

      // eslint-disable-next-line no-mixed-operators
      if (liability.shifted || !liability.shifted && !liability.shiftPossible) {
        const paymentResponse = await MagentoData.submitPayment(
          {
            payment_method_nonce: threeDSecureResponse.nonce,
            public_hash: publicHash,
            is_active_payment_token_enabler: true,
          },
          email,
          billingAddress,
          paymentMethod,
        );

        if (paymentResponse.status === 200) {
          setSuccess(true);
          setCheckoutSuccess();
        } else {
          setError(true);
          setLoading(false);
        }
      } else {
        setError(true);
        setLoading(false);
      }
    } else {
      setError(true);
      setLoading(false);
    }

    return true;
  };

  return (
    <div>
      <>
        {(error) && (
          <div className="Alert AlertError">
            Sorry, your order was unsuccessful.
          </div>
        )}
        {(savedCards.length > 0) && (
          <div className={styles.braintreeVaultAction}>
            <p>
              <svg viewBox="0 0 80 80">
                <use xlinkHref="#icon-announcement" />
              </svg>
              <strong>Click place order to complete your transaction</strong>
            </p>
            {(!success) && <Button type="submit" trigger={() => payNow()} label="Place order" buttonStyle="fullWidth primary" />}
            {(success) && (<p>Success! Please wait...</p>)}
            {(success && isDev) && (<p>Dev mode: Please manually clear your quote from browser data.</p>)}
          </div>
        )}
      </>
    </div>
  );
};

BraintreeVaultReorder.propTypes = {
  paymentMethod: PropTypes.shape({
    code: PropTypes.string,
  }),
  billingAddress: PropTypes.shape({}),
  emailAddress: PropTypes.string,
  setLoading: PropTypes.func,
  setCheckoutSuccess: PropTypes.func,
  savedCards: PropTypes.arrayOf(
    PropTypes.shape({
      details: PropTypes.string,
      publicHash: PropTypes.string,
      nonceUrl: PropTypes.string,
      type: PropTypes.string,
    }),
  ),
  grandTotal: PropTypes.number,
};

BraintreeVaultReorder.defaultProps = {
  paymentMethod: {
    code: '',
  },
  billingAddress: {},
  emailAddress: '',
  setLoading: () => {},
  setCheckoutSuccess: () => {},
  savedCards: [],
  grandTotal: 0,
};

export default BraintreeVaultReorder;
