/* eslint-disable react/jsx-props-no-spreading */
import React, { useState, Suspense } from 'react';
import PropTypes from 'prop-types';
import CheckoutModal from '../../common/CheckoutModal';
import styles from './style.module.scss';

const MinicartData = React.lazy(() => import('../../../containers/minicart/MinicartData'));
const EmailData = React.lazy(() => import('../../../containers/email/EmailData'));
const ShippingAddressData = React.lazy(() => import('../../../containers/shipping-address/ShippingAddressData'));
const ShippingMethodsData = React.lazy(() => import('../../../containers/shipping-methods/ShippingMethodsData'));
const PaymentMethodsData = React.lazy(() => import('../../../containers/payment-methods/PaymentMethodsData'));
const ShippingDetailsData = React.lazy(() => import('../../../containers/shipping-details/ShippingDetailsData'));


const Steps = (props) => {
  const {
    steps,
    setStep,
    setStepEditing,
    isExpress,
    setIsExpressPayment,
    isClickAndCollect,
    cartItems,
  } = props;

  const {
    order = [],
    current = 0,
  } = steps;

  const productCount = () => {
    let initialValue = 0;

    const sum = cartItems.reduce((accumulator, currentValue) => {
      return accumulator + currentValue.qty
    }, initialValue);

    return sum;
  }

  const blockTypes = {
    minicart: MinicartData,
    // shippingAddress: ShippingAddressData,
    shippingAddress: ShippingDetailsData,
    // shippingDetails: ShippingDetailsData,
    shippingMethods: ShippingMethodsData,
    paymentMethods: PaymentMethodsData,
    email: EmailData,
  };

  const renderBlock = (stepName, isEditing, isValid) => {
    const ThisBlock = blockTypes[stepName];

    if (!ThisBlock) {
      return false;
    }

    return (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <Suspense fallback={<div>Loading...</div>}>
        <ThisBlock {...props} isValid={isValid} isEditing={isEditing} />
      </Suspense>
    );
  };

  const isVisible = (step) => {
    const { checkingOut } = props;
    const stepIndex = order.findIndex((element) => element.name === step.name);

    if (step.name === 'minicart') {
      return !checkingOut ? `${styles.step} ${styles.active} ${styles.show}` : styles.step;
    }

    let stepStyles = styles.step;
    if (checkingOut) {
      stepStyles = `${styles.step} ${styles.show}`;
    }

    if (stepIndex === current) {
      stepStyles = `${styles.step} ${styles.show}  ${styles.active}`;
    }

    return stepStyles;
  };

  const checkStepReady = (step) => (!step.isValid || step.isEditing);
  const checkStepSet = (step, steps) => {
    const {
      current,
      order,
    } = steps;

    const stepPos = order.map((e) => e.name).indexOf(step.name);
    const stepIsPrevious = (stepPos < current);

    return (step.isValid && stepIsPrevious);
  };

  const [showEditDialog, setShowEditDialog] = useState(false);
  const [editStepIndex, setEditStepIndex] = useState(0);

  const editStep = (index, stepName) => {
    if (isExpress) {
      setShowEditDialog(true);
      setEditStepIndex(index);

      return;
    }

    setStep(index);
  };

  const revertToStandardCheckout = () => {
    setShowEditDialog(false);
    setIsExpressPayment(false);
    setStep(editStepIndex);
  };

  return (
    <>
      <CheckoutModal
        isOpen={showEditDialog}
        onRequestClose={() => { setShowEditDialog(false); }}
        contentLabel="Cancel Express Payment?"
        modalTitle="Cancel Express Payment?"
        modalContent="This action will cancel your express payment.&nbsp;
        You&apos;ll be able to pay at the end."
        exitModal={() => { setShowEditDialog(false); }}
        confirmAction={() => { revertToStandardCheckout(); }}
        showActions
      />
      <ul className={styles.steps}>
        {order.map((step, index) => {
          // Don't show method step if c&C and adjust payment step number
          let number = index;
          let displayName = step.displayName;
          // if (step.name === 'shippingMethods' && isClickAndCollect) return (<></>);
          if (step.name === 'shippingMethods') return (<></>);
          if (step.name === 'paymentMethods') {
            number = index - 1;
          }

          if (step.name === 'shippingAddress' && isClickAndCollect) {
              displayName = "Shipping details"
              if (isClickAndCollect) {
                  displayName = "Collection details"
              }
          }

          return (
            <li key={step.name} className={isVisible(step)}>
              <div className={styles.steps__item}>
                <button
                  type="button"
                  className={styles.steps__stepTitle}
                  onClick={() => editStep(index)}
                  disabled={checkStepReady(step)}
                >
                  <h3 className={styles[step.name]}>
                    {(step.name !== 'minicart') && (<>{number}.{' '}</>)}
                    {displayName}
                    {(step.name === 'minicart') && (<span> ({productCount()})</span>)}
                    {(step.name !== 'minicart' && checkStepSet(step, steps)) && (<span className={styles.stepEdit}>Edit</span>)}
                  </h3>
                </button>
                {renderBlock(step.name, step.isEditing, step.isValid)}
              </div>
            </li>
          )
        })}
      </ul>
    </>
  );
};

Steps.propTypes = {
  steps: PropTypes.shape({
    order: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      displayName: PropTypes.string.isRequired,
      isValid: PropTypes.bool,
      isEditing: PropTypes.bool,
    })),
    current: PropTypes.number,
  }),
  checkingOut: PropTypes.bool,
  setStep: PropTypes.func,
  isExpress: PropTypes.bool,
  setIsExpressPayment: PropTypes.func,
  isClickAndCollect: PropTypes.bool,
  cartItems: PropTypes.arrayOf(PropTypes.shape({})),
};

Steps.defaultProps = {
  steps: {
    current: 0,
    order: [],
  },
  checkingOut: false,
  setStep: () => {},
  isExpress: false,
  setIsExpressPayment: () => {},
  isClickAndCollect: false,
  cartItems: [],
};

export default Steps;
