import { connect } from 'react-redux';
import {
  clearState,
  setLoading,
  setCheckoutSuccess,
  setIsExpressPayment,
  getShippingMethods,
  setShippingInformation,
  setPaymentInformation,
  setPaymentMethod,
  setExpressPaymentData,
} from '../../store/actions/checkout';
import ExpressPayment from '../../components/wrappers/ExpressPayment';

const mapStateToProps = (state) => ({
  checkingOut: state.checkout.progress.inCheckout,
  paymentMethods: state.checkout.paymentMethods,
  paymentMethod: state.checkout.paymentMethod,
  grandTotal: state.checkout.cart.grandTotal,
  emailAddress: state.customer.form.emailAddress,
  billingAddress: state.customer.form.billingAddress,
  shippingMethods: state.checkout.shippingMethods,
  countries: state.customer.form.countries,
  isReorder: state.checkout.isReorder,
});

const mapDispatchToProps = (dispatch) => ({
  setShippingMethods: (address) => dispatch(getShippingMethods(address)),
  setShippingInformation: (shippingInformation, currentStep, proceed) => dispatch(setShippingInformation(shippingInformation, currentStep, proceed)),
  setPaymentMethod: (paymentMethod) => dispatch(setPaymentMethod(paymentMethod)),
  setPaymentInformation:
  (paymentInformation) => dispatch(setPaymentInformation(paymentInformation)),
  clearState: () => dispatch(clearState()),
  setLoading: (loading) => dispatch(setLoading(loading)),
  setCheckoutSuccess: (checkoutSuccess) => dispatch(setCheckoutSuccess(checkoutSuccess)),
  setIsExpressPayment: (isExpress) => dispatch(setIsExpressPayment(isExpress)),
  setExpressPaymentData: (expressData) => dispatch(setExpressPaymentData(expressData)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(ExpressPayment);
