export const setDataLayer = (cartData, currentStep, option) => {
    const items = cartData.items.map((item) => {
        return {
          name: item.name,
          id: item.sku,
          quantity: item.qty,
          price: item.price,
        };
      });

    if (window) {
      window.dataLayer = window.dataLayer || [];
      window.dataLayer.push({
        event: 'EECCheckout',
        ecommerce: {
          checkout: {
            actionField: {
              step: currentStep,
              option: option,
            },
            products: items,
          },
        },
      });
    }
};

export const removeProductDataLayer = (product) => {
  if (window) {
    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: 'EECRemoveFromCart',
      ecommerce: {
        remove: {
          products: product,
        },
      },
    });
  }
};


export default setDataLayer;