export const SET_DELIVERY_DATA = 'store/SET_DELIVERY_DATA';
export const SET_CART_DATA = 'store/SET_CART_DATA';
export const SET_CHECKOUT_STEP = 'store/SET_CHECKOUT_STEP';
export const SET_ON_CHECKOUT_PAGE = 'store/SET_ON_CHECKOUT_PAGE';
export const SET_CHECKOUT_STEP_VALID = 'store/SET_CHECKOUT_STEP_VALID';
export const SET_CHECKOUT_STEP_EDITING = 'store/SET_CHECKOUT_STEP_EDITING';
export const SET_CHECKING_OUT = 'store/SET_CHECKING_OUT';
export const CLEAR_STATE = 'store/CLEAR_STATE';
export const SET_LOADING = 'store/SET_LOADING';
export const SET_APP_ERROR = 'store/SET_APP_ERROR';
export const SET_CHECKOUT_SUCCESS = 'store/SET_CHECKOUT_SUCCESS';
export const SET_EXPRESS_PAYMENT = 'store/SET_EXPRESS_PAYMENT';
export const SET_SAVED_CARDS = 'store/SET_SAVED_CARDS';
export const SET_CAN_CHECKOUT = 'store/SET_CAN_CHECKOUT';
export const SET_REORDER_TRIGGERED = 'store/SET_REORDER_TRIGGERED';
export const SET_IS_REORDER = 'store/SET_IS_REORDER';
export const SET_LAST_ORDER_DATA = 'store/SET_LAST_ORDER_DATA';
export const SET_REORDER_SAVED_CARD = 'store/SET_REORDER_SAVED_CARD';
export const CANCEL_REORDER = 'store/CANCEL_REORDER';
