import React from 'react';
import ReactTooltip from "react-tooltip";
import PropTypes from 'prop-types';
import Button from '../Button';
import FormatPrice from '../../../helpers/FormatPrice';
import styles from './style.module.scss';
import {removeProductDataLayer} from '../../../helpers/DataLayer';

const Product = (props) => {
  const {
    itemUpdate,
    qty,
    qty_ordered: qtyOrdered,
    name,
    image,
    item_id: itemId,
    url,
    price,
    editable,
    removeProduct,
    error,
    sku,
  } = props;

  const handleChange = (e) => {
    itemUpdate(e.target.value, itemId);
  };

  const deleteItem = () => {

    const product = [{
        'name': name,
        'id': itemId,
        'price': price,
        'quantity': qty
    }];

    removeProductDataLayer(product);
    removeProduct(itemId);
  };

  const isDonation = sku.startsWith('donation-');

  return (
    <article className={editable ? `${styles.checkoutProduct} ${styles.checkoutProductEditable}` : styles.checkoutProduct}>
      <figure className={styles.checkoutProduct__image}>
        <a tabIndex="-1" href={url} className="product-item-photo">
          <img src={image} alt="" />
        </a>
      </figure>

      <div className={styles.checkoutProduct__details}>
        <h6>
          <span>
            {(qty > 0) && (qty)}
            {(qtyOrdered > 0) && (qtyOrdered)}
            x&nbsp;
          </span>
          {name}
        </h6>

        <p>{FormatPrice(price)}</p>

        {editable && !isDonation && (
          <div>
            <select onChange={handleChange} aria-label="Quantity" value={qty} className="button-select">
              <option value="1">1</option>
              <option value="2">2</option>
              <option value="3">3</option>
              <option value="4">4</option>
              <option value="5">5</option>
              <option value="6">6</option>
              <option value="7">7</option>
              <option value="8">8</option>
              <option value="9">9</option>
              <option value="10">10</option>
              <option value="11">11</option>
              <option value="12">12</option>
              <option value="13">13</option>
              <option value="14">14</option>
              <option value="15">15</option>
              <option value="16">16</option>
              <option value="17">17</option>
              <option value="18">18</option>
              <option value="19">19</option>
              <option value="20">20</option>
              <option value="21">21</option>
              <option value="22">22</option>
              <option value="23">23</option>
              <option value="24">24</option>
              <option value="25">25</option>
              <option value="26">26</option>
              <option value="27">27</option>
              <option value="28">28</option>
              <option value="29">29</option>
              <option value="30">30</option>
              <option value="31">31</option>
              <option value="32">32</option>
              <option value="33">33</option>
              <option value="34">34</option>
              <option value="35">35</option>
              <option value="36">36</option>
              <option value="37">37</option>
              <option value="38">38</option>
              <option value="39">39</option>
              <option value="40">40</option>
              <option value="41">41</option>
              <option value="42">42</option>
              <option value="43">43</option>
              <option value="44">44</option>
              <option value="45">45</option>
              <option value="46">46</option>
              <option value="47">47</option>
              <option value="48">48</option>
              <option value="49">49</option>
              <option value="50">50</option>
            </select>
          </div>
        )}

        {error && (
            <button type="button" data-tip data-for="registerTip" className={styles.checkoutProductError}>
              <strong className={styles.checkoutProductError__title}>
                Sorry, product not available
                <span>i</span>
              </strong>
              <span>Please remove from your basket before proceeding to checkout.</span>
            </button>
        )}

        {error && (
          <ReactTooltip id="registerTip" place="top" type="light" effect="solid" className={styles.checkoutProductError__content}>
            {error}
          </ReactTooltip>
        )}
      </div>

      {editable && (
        <div className={styles.checkoutProduct__actions}>
          <Button trigger={deleteItem} iconType="delete" label="close" showLabel={false} buttonStyle="delete link" />
        </div>
      )}
    </article>
  );
};

Product.propTypes = {
  qty: PropTypes.number,
  qty_ordered: PropTypes.number,
  name: PropTypes.string,
  image: PropTypes.string,
  item_id: PropTypes.string,
  url: PropTypes.string,
  price: PropTypes.string,
  itemUpdate: PropTypes.func,
  editable: PropTypes.bool,
  removeProduct: PropTypes.func,
  error: PropTypes.string,
  sku: PropTypes.string,
};

Product.defaultProps = {
  qty: 0,
  qty_ordered: 0,
  name: '',
  image: '',
  item_id: '',
  url: '',
  price: '',
  itemUpdate: () => {},
  editable: false,
  removeProduct: () => {},
  error: '',
  sku: '',
};

export default Product;
