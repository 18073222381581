/* eslint-disable no-undef */
import {
  setCartData,
  setStoreReorderTriggered,
  loadLastOrder,
  cancelStateReorder,
  setStep,
} from '../store/actions/checkout';

export default (store) => {
  if (window.jQuery) {
    const storeState = store.getState();
    const { loader } = storeState.checkout;
    jQuery(document).on('ajax:addToCart', () => {
      if (!loader) {
        jQuery('body').addClass('checkout-open');
        store.dispatch(setCartData());
      }
    });
  }

  document.addEventListener('headlessReorder', () => {
    store.dispatch(setStoreReorderTriggered(true));
    store.dispatch(loadLastOrder());
    jQuery('body').addClass('checkout-open');
  });

  document.addEventListener('openCartModal', () => {
    const storeState = store.getState();
    const { reOrderTriggered, isReorder } = storeState.checkout;

    if (reOrderTriggered && !isReorder) {
      store.dispatch(setStep(0));
      store.dispatch(setStoreReorderTriggered(false));
    }

    jQuery('body').addClass('checkout-open');
  });

  document.addEventListener('exitCartModal', () => {
    const storeState = store.getState();
    const { isReorder } = storeState.checkout;

    if (isReorder) {
      store.dispatch(cancelStateReorder(true));
    } else {
      document.body.classList.remove('checkout-open');
    }
  });
};
