/* eslint-disable react/jsx-one-expression-per-line */
import React from 'react';
import PropTypes from 'prop-types';
import styles from './style.module.scss';

const SelectedDeliveryType = (props) => {
  const { address, shippingMethod } = props;

  if (!address.street) {
    return <></>;
  }

  const Icon = () => {
    if (shippingMethod === 'kkcc_STORE') {
      return (
        <svg viewBox="0 0 36 32">
          <use xlinkHref="#icon-click-and-collect" />
        </svg>
      );
    }

    return (
      <svg viewBox="0 0 47 32">
        <use xlinkHref="#icon-delivery" />
      </svg>
    );
  };

  const Label = () => {
    if (shippingMethod === 'kkcc_STORE') {
      return (
        <>Collection from: </>
      );
    }

    return (
      <>Deliver to: </>
    );
  };

  return (
    <p className={styles.selectedDeliveryType}>
      <Icon />
      <div>
        <strong><Label /></strong>
        {address.company && (<span>{address.company},{'\u00A0'}</span>)}
        {address.street[0] && (<span>{address.street[0]},{'\u00A0'}</span>)}
        {address.street[1] && (<span>{address.street[1]},{'\u00A0'}</span>)}
        {address.city && (<span>{address.city},{'\u00A0'}</span>)}
        {address.region && (<span>{address.region},{'\u00A0'}</span>)}
        {address.country_id && (<span>{address.country_id},{'\u00A0'}</span>)}
        {address.postcode && (<span>{address.postcode}</span>)}
      </div>
    </p>
  );
};

SelectedDeliveryType.propTypes = {
  address: PropTypes.shape({
    firstname: PropTypes.string,
    lastname: PropTypes.string,
    company: PropTypes.string,
    street: PropTypes.arrayOf(PropTypes.string),
    city: PropTypes.string,
    postcode: PropTypes.string,
    country_id: PropTypes.string,
    telephone: PropTypes.string,
    region: PropTypes.string,
  }),
  shippingMethod: PropTypes.string,
};

SelectedDeliveryType.defaultProps = {
  address: {
    firstname: '',
    lastname: '',
    company: '',
    street: ['', ''],
    city: '',
    postcode: '',
    country_id: '',
    telephone: '',
    region: '',
  },
  shippingMethod: '',
};

export default SelectedDeliveryType;
