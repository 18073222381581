import { connect } from 'react-redux';
import Checkout from '../../components/wrappers/Checkout';
import {
  setStep,
  setCartData,
  setCheckingOut,
  setOnCheckoutPage,
  setLoading,
  setAppError,
  setCheckoutSuccess,
  setDeliveryData,
  setShippingAddress,
  setStoreShippingAddressSet,
  clearState,
  setStoreReorderTriggered,
  loadLastOrder,
  cancelReorderAction,
  cancelStateReorder,
} from '../../store/actions/checkout';

const mapStateToProps = (state) => ({
  checkingOut: state.checkout.progress.inCheckout,
  checkoutPage: state.checkout.checkoutPage,
  currentStep: state.checkout.steps.current,
  cartItems: state.checkout.cart.items,
  loader: state.checkout.loader,
  appError: state.checkout.appError,
  checkoutSuccess: state.checkout.checkoutSuccess,
  reOrderTriggered: state.checkout.reOrderTriggered,
  isReorder: state.checkout.isReorder,
  cancelReorder: state.checkout.cancelReorder,
});

const mapDispatchToProps = (dispatch) => ({
  setStep: (step) => dispatch(setStep(step)),
  setCartData: () => dispatch(setCartData()),
  setCheckingOut: (checkingOut) => dispatch(setCheckingOut(checkingOut)),
  setOnCheckoutPage: (onCheckout) => dispatch(setOnCheckoutPage(onCheckout)),
  setLoading: (loading) => dispatch(setLoading(loading)),
  setAppError: (error) => dispatch(setAppError(error)),
  setCheckoutSuccess: (checkoutSuccess) => dispatch(setCheckoutSuccess(checkoutSuccess)),
  setDeliveryData: (deliveryData) => dispatch(setDeliveryData(deliveryData)),
  setShippingAddress: (address, billingSameAsShipping) => dispatch(setShippingAddress(address, billingSameAsShipping)),
  setStoreShippingAddressSet: (isSet) => dispatch(setStoreShippingAddressSet(isSet)),
  clearState: () => dispatch(clearState()),
  setStoreReorderTriggered: (reOrderTriggered) => dispatch(setStoreReorderTriggered(reOrderTriggered)),
  loadLastOrder: () => dispatch(loadLastOrder()),
  cancelReorderAction: () => dispatch(cancelReorderAction()),
  cancelStateReorder: (cancel) => dispatch(cancelStateReorder(cancel)),
});

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(Checkout);
