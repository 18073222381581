export const SET_CUSTOMER = 'store/SET_CUSTOMER';
export const SET_CUSTOMER_EMAIL = 'store/SET_CUSTOMER_EMAIL';
export const SET_CUSTOMER_ADDRESSES = 'store/SET_CUSTOMER_ADDRESSES';
export const SET_CUSTOMER_REGISTERED = 'store/SET_CUSTOMER_REGISTERED';
export const SET_COUNTRIES = 'store/SET_COUNTRIES';
export const SET_CUSTOMER_SHIPPING_ADDRESS = 'store/SET_CUSTOMER_SHIPPING_ADDRESS';
export const SET_CUSTOMER_BILLING_ADDRESS = 'store/SET_CUSTOMER_BILLING_ADDRESS';
export const SET_CUSTOMER_GIFT_ADDRESS = 'store/SET_CUSTOMER_GIFT_ADDRESS';
export const SET_CUSTOMER_BILLING_ADDRESS_SET = 'store/SET_CUSTOMER_BILLING_ADDRESS_SET';
export const SET_CUSTOMER_SHIPPING_ADDRESS_SET = 'store/SET_CUSTOMER_SHIPPING_ADDRESS_SET';
export const SET_CUSTOMER_GIFT_ADDRESS_SET = 'store/SET_CUSTOMER_GIFT_ADDRESS_SET';
export const SET_CUSTOMER_LOGIN_STATUS = 'store/SET_CUSTOMER_LOGIN_STATUS';
export const SET_CUSTOMER_LOGIN_JWT = 'store/SET_CUSTOMER_LOGIN_JWT';
export const SET_CUSTOMER_LOGIN_STEP = 'store/SET_CUSTOMER_LOGIN_STEP';
