/* eslint-disable no-undef */
export default () => {
  document.addEventListener('keydown', (event) => {
    document.body.classList.add('is-keyboard');
  });

  document.addEventListener('click', () => {
    document.body.classList.remove('is-keyboard');
  });
};
