/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { BraintreeInstance } from '../../../../services/Braintree';
import { MagentoData } from '../../../../services/MagentoApi';

// Krispy Kreme Only
import { getIsClickAndCollect } from '../../../../helpers/MageStorage';
import { getCurrencyCode } from '../../../../helpers/FormatPrice';

const isClickAndCollect = getIsClickAndCollect();

const PayPal = (props) => {
  const isDev = (process.env.NODE_ENV === 'development');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);

  const {
    grandTotal,
    emailAddress,
    paymentMethod,
    billingAddress,
    setCheckoutSuccess,
    expressPayment,
    setExpressPaymentData,
    setIsExpressPayment,
    setLoading,
    isReorder,
  } = props;

  const buttonID = expressPayment ? 'checkout-paypal-button-container-express' : 'checkout-paypal-button-container';

  useEffect(() => {
    /**
     * Load PayPal
     */
    const payPalScript = 'https://www.paypalobjects.com/api/checkout.min.js';
    const paypalId = 'paypal-checkout';

    const existingPPScript = document.getElementById(paypalId);

    if (grandTotal > 0) {
      if (typeof (existingPPScript) === 'undefined' || existingPPScript === null) {
        const sT = document.createElement('script');
        sT.setAttribute('type', 'text/javascript');
        sT.setAttribute('src', payPalScript);
        sT.setAttribute('id', paypalId);
        document.body.appendChild(sT);
      }
    }
  }, [grandTotal]);

  useEffect(() => {
    const buttonEl = document.querySelector(`#${buttonID}`);
    const PayPalInstance = BraintreeInstance.createPayPalInstance();

    if (!success && buttonEl) {
      // clear the button
      buttonEl.innerHTML = '';

      PayPalInstance.then((paypalCheckoutInstance) => {
        if (typeof window === 'undefined') {
          return false;
        }

        // eslint-disable-next-line prefer-const
        let { paypal = null } = window;
        if (paypal === null) {
          return false;
        }

        const renderData = {
          env: BraintreeInstance.getBraintreeEnvironment(),
          funding: {
            disallowed: [window.paypal.FUNDING.CREDIT],
          },
          commit: true,
          style: {
            label: 'checkout',
            size: 'responsive',
            shape: 'rect',
            color: 'blue',
            tagline: false,
          },
          payment() {
            setLoading(true);
            return paypalCheckoutInstance.createPayment({
              amount: parseFloat(grandTotal).toFixed(2),
              flow: 'checkout',
              currency: getCurrencyCode(), // @todo get defined currency code
              enableShippingAddress: expressPayment,
              shippingAddressEditable: expressPayment,
            });
          },
          onAuthorize(data) {
            return paypalCheckoutInstance.tokenizePayment(data)
              .then((payload) => {
                if (expressPayment) {
                  const address = payload.details.shippingAddress;
                  const addressFinal = {
                    street: [address.line1, address.line2],
                    city: address.city,
                    // eslint-disable-next-line no-nested-ternary
                    region: (typeof address.region !== 'undefined' ? address.region : (typeof address.state !== 'undefined' ? address.state : '')),
                    country_id: address.countryCode,
                    postcode: address.postalCode,
                    company: '',
                    telephone: typeof address.phone !== 'undefined' ? address.phone : '00000000000',
                    firstname: payload.details.firstName,
                    lastname: payload.details.lastName,
                  };

                  const paymentData = {
                    paymentMethod: 'braintree_paypal',
                    paymentData: {
                      payment_method_nonce: payload.nonce,
                    },
                    email: payload.details.email,
                    address: {
                      shipping: addressFinal,
                      billing: addressFinal,
                    },
                    completeOrder: (isClickAndCollect || isReorder),
                    isClickAndCollect,
                  };

                  setIsExpressPayment(true);
                  setExpressPaymentData(paymentData);
                } else {
                  const paymentRequest = MagentoData.submitPayment(
                    { payment_method_nonce: payload.nonce },
                    emailAddress,
                    billingAddress,
                    paymentMethod,
                  );

                  paymentRequest.then(() => {
                    setSuccess(true);
                    setCheckoutSuccess();
                  }, (r) => {
                    setLoading(false);
                    console.log(r);
                    setError('There was an error with your payment. Please try again, or an alternative payment method');
                  });
                }
              }).catch((r) => {
                console.log(r);
                setError('There was an error with your payment. Please try again, or an alternative payment method');
                setLoading(false);
              });
          },
          onCancel(data) {
            console.log('checkout.js payment cancelled', JSON.stringify(data, 0, 2));
            setLoading(false);
          },
          onError(err) {
            console.error('checkout.js error', err);
            setError('There was an error with your payment. Please try again, or an alternative payment method');
            setLoading(false);
          },
        };


        // Leaving in case credit is ever required
        // const payPalCreditThreshold = 99;
        // if (grandTotal > payPalCreditThreshold) {
        //   renderData.funding = {
        //     allowed: [window.paypal.FUNDING.CREDIT],
        //   };
        // } else {
        //   renderData.funding = {
        //     disallowed: [window.paypal.FUNDING.CREDIT],
        //   };
        // }

        return paypal.Button.render(renderData, `#${buttonID}`).then(() => {
          // todo loading state
        });
      });
    }
  }, [
    success,
    grandTotal,
    expressPayment,
    emailAddress,
    billingAddress,
    paymentMethod,
  ]);


  return (
    <div>
      <div id={buttonID} className="braintree-paypal-button" />
      {(error) && (<div className="Alert AlertError">{error}</div>)}
      {(success) && (<p>Success! Please wait...</p>)}
      {(success && isDev) && (<p>Dev mode: Please manually clear your quote from browser data.</p>)}
    </div>
  );
};

PayPal.propTypes = {
  paymentMethod: PropTypes.shape({
    code: PropTypes.string,
  }),
  grandTotal: PropTypes.number,
  billingAddress: PropTypes.shape({}),
  emailAddress: PropTypes.string,
  setCheckoutSuccess: PropTypes.func,
  expressPayment: PropTypes.bool,
  setExpressPaymentData: PropTypes.func,
  setIsExpressPayment: PropTypes.func,
  setLoading: PropTypes.func,
  isReorder: PropTypes.bool,
};

PayPal.defaultProps = {
  paymentMethod: {
    code: '',
  },
  grandTotal: 0,
  billingAddress: {},
  emailAddress: '',
  setCheckoutSuccess: () => {},
  expressPayment: false,
  setExpressPaymentData: () => {},
  setIsExpressPayment: () => {},
  setLoading: () => {},
  isReorder: false,
};

export default PayPal;
