const { genecheckout: { currencySymbol = '£' } = {} } = window;

export default (price, showFree = true) => {
  if (showFree
    && (!price || price === undefined || parseFloat(price) === 0)) {
    return 'Free';
  }

  return `${currencySymbol}${parseFloat(price).toFixed(2)}`;
};

export const getCurrencyCode = () => {
  if (currencySymbol === '€') {
    return 'EUR';
  }

  return 'GBP';
};
