import React from 'react';
import PropTypes from 'prop-types';

import FormatPrice from '../../../helpers/FormatPrice';

import styles from './style.module.scss';

const formatSegmentName = (name) => {
  let formattedName;

  switch (name) {
    case 'Grand Total':
      formattedName = 'Order Total';
      break;
    case 'Tax':
      formattedName = 'VAT';
      break;
    case 'Shipping & Handling (Click & Collect - Store)':
      formattedName = 'Delivery (Click & Collect - Store)';
      break;
    case 'Shipping & Handling':
      formattedName = 'Delivery';
      break;
    default:
      formattedName = name;
      break;
  }

  return formattedName;
};

// Format total price
const formatTotalValue = (total) => {
  // If discount then rearrange '-' symbol
  if (total.code === 'discount') {
    let price = Math.abs(total.value);
    price = FormatPrice(price, false);
    return `-${price}`;
  }
  // Return formatted price
  return FormatPrice(total.value, false);
};

const showGiftWrapping = false;

const Totals = ({ segments }) => {
  let totals = segments;
  if (!showGiftWrapping) {
    totals = segments.filter((segment) => segment.code !== 'giftwrapping');
  }

  return (
    <table className={styles.totalsTable}>
      <caption className="table-caption">Order Summary</caption>
      <tbody>
        {totals.map((total) => (
          <tr key={total.title} className={total.code}>
            {total.title && (<td><strong>{formatSegmentName(total.title)}</strong></td>)}
            {total.title && (<td>{formatTotalValue(total)}</td>)}
          </tr>
        ))}
      </tbody>
    </table>
  );
};

Totals.propTypes = {
  segments: PropTypes.arrayOf(
    PropTypes.shape({
      code: PropTypes.string,
      value: PropTypes.number,
      title: PropTypes.string,
    }),
  ),
};

Totals.defaultProps = {
  segments: [],
};

export default Totals;
