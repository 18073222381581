import * as checkoutConstant from '../constants/checkout';
import * as paymentConstant from '../constants/payment';
import * as shippingConstant from '../constants/shipping';

export const initialState = {
  deliveryData: { },
  checkoutPage: false,
  cart: {
    cart_type: 'guest',
    masked_id: null,
    grandTotal: 0,
    items: [],
  },
  steps: {
    current: 0,
    order: [
      {
        name: 'minicart',
        displayName: 'Shopping Basket',
        isValid: true,
        isEditing: false,
      },
      {
        name: 'email',
        displayName: 'Contact Information',
        isValid: false,
        isEditing: false,
      },
      {
        name: 'shippingAddress',
        displayName: 'Shipping details',
        isValid: false,
        isEditing: false,
      },
      {
        name: 'shippingMethods',
        displayName: 'Shipping Methods',
        isValid: false,
        isEditing: false,
      },
      {
        name: 'paymentMethods',
        displayName: 'Payment Methods',
        isValid: false,
        isEditing: false,
      },
    ],
  },
  shippingMethods: [],
  shippingMethod: {
    index: 0,
    method_code: '',
    carrier_code: '',
    methodTimes: [],
    methodTime: '',
  },
  orderDateTime: '',
  paymentMethods: [],
  paymentMethod: {
    code: '',
  },
  savedCards: [],
  progress: {
    inCheckout: false,
    shippingAddress: false,
    shippingMethod: false,
    customer: false,
    payment: false,
  },
  loader: false,
  appError: null,
  checkoutSuccess: false,
  isExpress: false,
  expressPaymentData: null,
  canCheckout: true,
  reOrderTriggered: false,
  isReorder: false,
  lastOrderData: {},
  reorderSaveCard: false,
  cancelReorder: false,
};

const checkout = (state = initialState, action) => {
  switch (action.type) {
    case checkoutConstant.SET_DELIVERY_DATA:
      return { ...state, deliveryData: action.deliveryData };
    case checkoutConstant.SET_CHECKOUT_STEP:
      return { ...state, steps: { current: action.step, order: state.steps.order } };
    case checkoutConstant.SET_CHECKOUT_STEP_VALID: {
      const { steps: { order } } = state;
      const step = order.find((thisStep) => thisStep.name === action.data.step);
      step.isValid = action.data.isValid;
      return { ...state, steps: { ...state.steps, order } };
    }
    case checkoutConstant.SET_CHECKOUT_STEP_EDITING: {
      const { steps: { order } } = state;
      const step = order.find((thisStep) => thisStep.name === action.data.step);
      step.isEditing = action.data.isEditing;
      return { ...state, steps: { ...state.steps, order } };
    }
    case checkoutConstant.SET_CHECKING_OUT: {
      return { ...state, progress: { ...state.progress, inCheckout: action.checkingOut } };
    }
    case shippingConstant.SET_ORDER_DATE_TIME: {
      return { ...state, orderDateTime: action.orderDateTime };
    }
    case checkoutConstant.SET_CART_DATA:

      // API returns null, return empty array instead
      // @todo - move into api wrapper
      if (!action.cartData.items) {
        // eslint-disable-next-line no-param-reassign
        action.cartData.items = [];
      }

      if (!action.cartData.cart) {
        // eslint-disable-next-line no-param-reassign
        action.cartData.cart = {};
      }

      // eslint-disable-next-line no-case-declarations
      let baseGrandtotal = 0;
      if (action.cartData.cart_type !== 'empty') {
        // eslint-disable-next-line no-case-declarations
        const { cart = {} } = action.cartData;
        // eslint-disable-next-line no-case-declarations
        baseGrandtotal = cart.base_grand_total;
      }

      return { ...state, cart: { ...action.cartData, grandTotal: baseGrandtotal } };
    case checkoutConstant.SET_ON_CHECKOUT_PAGE:
      return { ...state, checkoutPage: action.checkoutPage };
    case shippingConstant.SET_SHIPPING_METHODS:
      return { ...state, shippingMethods: action.methods };
    case shippingConstant.SET_SHIPPING_METHOD:
      return { ...state, shippingMethod: action.method };
    case paymentConstant.SET_PAYMENT_METHODS:
      return { ...state, paymentMethods: action.paymentMethods };
    case paymentConstant.SET_PAYMENT_METHOD:
      return { ...state, paymentMethod: action.paymentMethod };
    case checkoutConstant.SET_LOADING:
      return { ...state, loader: action.loading };
    case checkoutConstant.SET_APP_ERROR:
      return { ...state, appError: action.error };
    case checkoutConstant.SET_CHECKOUT_SUCCESS:
      return { ...state, checkoutSuccess: action.checkoutSuccess };
    case checkoutConstant.SET_EXPRESS_PAYMENT:
      return { ...state, isExpress: action.expressPayment };
    case paymentConstant.SET_EXPRESS_PAYMENT_DATA:
      return { ...state, expressPaymentData: action.expressData };
    case checkoutConstant.SET_SAVED_CARDS:
      return { ...state, savedCards: action.tokens };
    case checkoutConstant.SET_CAN_CHECKOUT:
      return { ...state, canCheckout: action.canCheckout };
    case checkoutConstant.SET_IS_REORDER:
      return { ...state, isReorder: action.isReorder };
    case checkoutConstant.SET_REORDER_TRIGGERED:
      return { ...state, reOrderTriggered: action.reOrderTriggered };
    case checkoutConstant.SET_LAST_ORDER_DATA:
      return { ...state, lastOrderData: action.lastOrderData };
    case checkoutConstant.SET_REORDER_SAVED_CARD:
      return { ...state, reorderSaveCard: action.savedCard };
    case checkoutConstant.CANCEL_REORDER:
      return { ...state, cancelReorder: action.cancelReorder };
    default:
      return state;
  }
};

export default checkout;
