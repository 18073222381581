/* eslint-disable jsx-a11y/control-has-associated-label */
/* eslint-disable no-undef */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { BraintreeInstance } from '../../../../services/Braintree';
import { MagentoData } from '../../../../services/MagentoApi';
import GooglePayStyles from './style.module.scss';

// Krispy Kreme Only
import { getIsClickAndCollect } from '../../../../helpers/MageStorage';
import { getCurrencyCode } from '../../../../helpers/FormatPrice';

const isClickAndCollect = getIsClickAndCollect();

const GooglePay = (props) => {
  const isDev = (process.env.NODE_ENV === 'development');

  const [success, setSuccess] = useState(false);
  const [error, setError] = useState(null);
  const [scriptReady, setScriptReady] = useState(false);

  const {
    grandTotal,
    emailAddress,
    paymentMethod,
    billingAddress,
    setLoading,
    setCheckoutSuccess,
    expressPayment,
    setExpressPaymentData,
    setIsExpressPayment,
  } = props;

  useEffect(() => {
    /**
     * Load GPay
     */
    const googleScript = 'https://pay.google.com/gp/p/js/pay.js';
    const googleId = 'google-checkout';
    const existingGPScript = document.getElementById(googleId);

    if (grandTotal > 0) {
      if (typeof (existingGPScript) === 'undefined' || existingGPScript === null) {
        const sT = document.createElement('script');
        sT.setAttribute('type', 'text/javascript');
        sT.setAttribute('src', googleScript);
        sT.setAttribute('id', googleId);

        sT.addEventListener('load', () => {
          setScriptReady(true);
        });

        document.body.appendChild(sT);
      } else {
        // Already got the script
        setScriptReady(true);
      }
    }
  }, [grandTotal]);

  useEffect(() => {
    // nothing
  }, [
    success,
    grandTotal,
    emailAddress,
    billingAddress,
    paymentMethod,
    scriptReady,
    expressPayment,
  ]);

  const buttonId = expressPayment ? 'google-pay-button-express' : 'google-pay-button';

  const triggerPayment = async (event) => {
    setLoading(true);
    setIsExpressPayment(false);
    event.preventDefault();

    const googlePayment = BraintreeInstance.googlePaymentRequest(window.google);

    console.log({
      emailAddress,
      expressPayment,
      grandTotal,
      billingAddress,
      paymentMethod,
    });

    googlePayment.then((response) => {
      if (!response) { return false; }
      const { isReadyToPay, gPayInstance, googleClientInstance } = response;

      if (isReadyToPay.result) {
        const paymentDataRequest = gPayInstance.createPaymentDataRequest({
          transactionInfo: {
            currencyCode: getCurrencyCode(),
            totalPriceStatus: 'FINAL',
            totalPrice: grandTotal.toString(),
          },
          emailRequired: true,
        });

        const cardPaymentMethod = paymentDataRequest.allowedPaymentMethods[0];
        cardPaymentMethod.parameters.billingAddressRequired = true;
        cardPaymentMethod.parameters.billingAddressParameters = {
          format: 'FULL',
          phoneNumberRequired: true,
        };

        if (expressPayment) {
          paymentDataRequest.shippingAddressRequired = true;
          paymentDataRequest.shippingAddressParameters = {
            phoneNumberRequired: true,
          };
        }

        googleClientInstance.loadPaymentData(paymentDataRequest).then((paymentData) => {
          const responseData = paymentData;

          return gPayInstance.parseResponse(responseData).then((result) => {
            if (expressPayment) {
              const billingLastname = responseData.paymentMethodData.info.billingAddress.name.split(' ').slice(1).toString() || 'NOLASTNAME';
              const shippingLastname = responseData.shippingAddress ? responseData.shippingAddress.name.split(' ').slice(1).toString() : 'NOLASTNAME';

              const newBillingAddress = {
                street: [
                  responseData.paymentMethodData.info.billingAddress.address1,
                  responseData.paymentMethodData.info.billingAddress.address2,
                ],
                city: responseData.paymentMethodData.info.billingAddress.locality,
                // eslint-disable-next-line no-nested-ternary
                country_id: responseData.paymentMethodData.info.billingAddress.countryCode,
                postcode: responseData.paymentMethodData.info.billingAddress.postalCode,
                company: '',
                telephone: typeof responseData.paymentMethodData.info.billingAddress.phoneNumber !== 'undefined' ? responseData.paymentMethodData.info.billingAddress.phoneNumber : '',
                region: typeof responseData.paymentMethodData.info.billingAddress.administrativeArea !== 'undefined' ? responseData.paymentMethodData.info.billingAddress.administrativeArea : '',
                firstname: responseData.paymentMethodData.info.billingAddress.name.split(' ')[0],
                lastname: billingLastname,
                email: responseData.email,
              };

              const newShippingAddress = {
                street: [
                  responseData.shippingAddress.address1,
                  responseData.shippingAddress.address2,
                ],
                city: responseData.shippingAddress.locality,
                // eslint-disable-next-line no-nested-ternary
                country_id: responseData.shippingAddress.countryCode,
                postcode: responseData.shippingAddress.postalCode,
                company: '',
                telephone: typeof responseData.shippingAddress.phoneNumber !== 'undefined' ? responseData.shippingAddress.phoneNumber : '',
                region: typeof responseData.shippingAddress.administrativeArea !== 'undefined' ? responseData.shippingAddress.administrativeArea : '',
                firstname: responseData.shippingAddress.name.split(' ')[0],
                lastname: shippingLastname,
                email: responseData.email,
              };

              const expressPaymentData = {
                paymentMethod: 'braintree_googlepay',
                paymentData: {
                  payment_method_nonce: result.nonce,
                },
                email: newShippingAddress.email,
                address: {
                  shipping: newShippingAddress,
                  billing: newBillingAddress,
                },
                completeOrder: true,
                isClickAndCollect,
                shippingMethod: {
                  method_code: 'STORE',
                  carrier_code: 'kkcc',
                },
              };

              setIsExpressPayment(true);
              setExpressPaymentData(expressPaymentData);
            } else {
              const paymentRequest = MagentoData.submitPayment(
                { payment_method_nonce: result.nonce },
                emailAddress,
                billingAddress,
                paymentMethod,
              );

              paymentRequest.then(() => {
                setSuccess(true);
                setCheckoutSuccess();
              }, (r) => {
                setLoading(false);
                console.log(r);
                setError('There was an error with your payment. Please try again, or an alternative payment method');
              });
            }
          });
        }).catch((err) => {
          console.log(err);

          setLoading(false);

          const { statusCode = '' } = err;

          if (statusCode === 'CANCELED') {
            setError('Payment was cancelled.');
          }

          return false;
        });
      }

      return false;
    });
  };

  return (
    <div>
      <button
        type="button"
        onClick={(event) => { triggerPayment(event); }}
        id={buttonId}
        className={GooglePayStyles.GpayButton}
        title="Pay with Google Pay"
      />
      {(error) && (<div className="Alert AlertError">{error}</div>)}
      {(success) && (<p>Success! Please wait...</p>)}
      {(success && isDev) && (<p>Dev mode: Please manually clear your quote from browser data.</p>)}
    </div>
  );
};

GooglePay.propTypes = {
  paymentMethod: PropTypes.shape({
    code: PropTypes.string,
  }),
  grandTotal: PropTypes.number,
  billingAddress: PropTypes.shape({}),
  emailAddress: PropTypes.string,
  setLoading: PropTypes.func,
  setCheckoutSuccess: PropTypes.func,
  expressPayment: PropTypes.bool,
  setExpressPaymentData: PropTypes.func,
  setIsExpressPayment: PropTypes.func,
};

GooglePay.defaultProps = {
  paymentMethod: {
    code: '',
  },
  grandTotal: 0,
  billingAddress: {},
  emailAddress: '',
  setLoading: () => {},
  setCheckoutSuccess: () => {},
  expressPayment: false,
  setExpressPaymentData: () => {},
  setIsExpressPayment: () => {},
};

export default GooglePay;
