/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import Product from '../Product';
import styles from './style.module.scss';

const ProductList = ({
  cartData,
  itemUpdate,
  editable,
  removeProduct,
}) => {
  const { items = [] } = cartData;

  return (
    <ul className={styles.productList}>
      {items.map((product) => (
        <li key={product.item_id}>
          <Product
            editable={editable}
            itemUpdate={itemUpdate}
            removeProduct={removeProduct}
            {...product}
          />
        </li>
      ))}
    </ul>
  );
};

ProductList.propTypes = {
  cartData: PropTypes.shape({
    items: PropTypes.arrayOf(
      PropTypes.shape({}),
    ),
  }),
  itemUpdate: PropTypes.func,
  removeProduct: PropTypes.func,
  editable: PropTypes.bool,
};

ProductList.defaultProps = {
  cartData: {
    items: [],
  },
  itemUpdate: () => {},
  removeProduct: () => {},
  editable: false,
};

export default ProductList;
