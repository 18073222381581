/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Formik, Form, Field } from 'formik';
import formStyles from '../../../styles/input.module.scss';
import Button from '../Button';

const Coupon = ({
  applyCoupon,
  removeCoupon,
  cartData,
}) => {
  const [showCoupon, setshowCoupon] = useState(false);
  const { cart = { } } = cartData;
  const { coupon_code: couponCode } = cart;

  return (
    <div>
      {couponCode && (
        <>
          <p>Discount code: {couponCode}</p>
          <Button buttonStyle="link" label="Remove Discount" trigger={() => { removeCoupon(couponCode); }} />
        </>
      )}
      {!couponCode && (
        <>
          <Button buttonStyle="link" label={showCoupon ? 'Cancel Discount' : 'Apply Discount'} trigger={() => { setshowCoupon(!showCoupon); }} />
          {showCoupon && (
            <Formik
              initialValues={{ coupon: '' }}
              validateOnMount
              validate={(values) => {
                const errors = {};


                return errors;
              }}
              onSubmit={(values, { setSubmitting }) => {
                setTimeout(() => {
                  applyCoupon(values.coupon);
                  setSubmitting(false);
                }, 400);
              }}
            >
              {({
                values,
                errors,
                touched,
                handleChange,
                handleBlur,
                handleSubmit,
                isSubmitting,
                /* and other goodies */
              }) => (
                <Form onSubmit={handleSubmit}>
                  <label className={formStyles.label} htmlFor="coupon">Enter Discount Code</label>
                  <div className={formStyles.fieldGroup}>
                    <div className={formStyles.field} key="coupon-code">
                      <div className={formStyles.control}>
                        <Field
                          type="text"
                          name="coupon"
                          onChange={handleChange}
                          onBlur={handleBlur}
                          value={values.coupon}
                        />
                        {errors.coupon ? (
                          <div className="Alert AlertError">{errors.coupon}</div>
                        ) : null}
                      </div>
                    </div>

                    <div className={formStyles.formActions}>
                      <button type="submit" disabled={isSubmitting}>
                        Submit
                      </button>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          )}
        </>
      )}
    </div>
  );
};

Coupon.propTypes = {
  applyCoupon: PropTypes.func,
  removeCoupon: PropTypes.func,
  cartData: PropTypes.shape({
    cart_type: PropTypes.string,
    cart: PropTypes.shape({
      coupon_code: PropTypes.string,
    }),
  }),
};

Coupon.defaultProps = {
  applyCoupon: () => {},
  removeCoupon: () => {},
  cartData: {
    cart_type: '',
    cart: {
      coupon_code: null,
    },
  },
};

export default Coupon;
